
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment/moment";
import axios from "axios";
import toast from "react-hot-toast";
import { useCatchHandler } from "../../utils/utilities"
const { REACT_APP_MY_ENV } = process.env;

const ClientInvoice = () => {
	const catchHandle = useCatchHandler();
	const currentUrl = new URL(window.location.href);
	const subscriptionId = currentUrl.searchParams.get('subscriptionId');
	const slug = currentUrl.searchParams.get('slug');
	const clientId = currentUrl.searchParams.get('clientId');
	const location = useLocation();
	let token = localStorage.getItem("adminToken");
	const [subscriptionIdDetail, setSubscriptionIdDetail] = useState("");
	const [viewClient, setViewClient] = useState("");

	useEffect(() => {
		if (slug) {
			GetClientsDetail(slug);
		}
		if (subscriptionId) {
			getSubscriptionIdDetail(subscriptionId)
		}
	}, [slug, subscriptionId, clientId]);

	const GetClientsDetail = async (slug) => {
		const data = {
			isView: true,
			slug: slug ? slug : ""
		};
		const response = await axios.post(REACT_APP_MY_ENV + "/outlet/", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
			if (response.data.status === 200) {
				if (response.data.data) {
					setViewClient(response.data.data.outlet)
				}
			} else {
			}
		}).catch(function (error) {
			toast.error("Something went wrong, please contact Administrator!")
			catchHandle(error)
			toast.error("Something went wrong, please contact Administrator!")
		});
	};

	const getSubscriptionIdDetail = async (subscriptionId) => {
		let data = {
			subscriptionId: subscriptionId ? subscriptionId : ""
		}
		const response = await axios.post(REACT_APP_MY_ENV + "/subscription-id-detail", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
			if (response.data.status === 200) {
				setSubscriptionIdDetail(response.data.data.subscription)
				setTimeout(() => {
					window.print();
				}, 1000);
			} else {
				setSubscriptionIdDetail([]);
			}
		}).catch(function (error) {
			catchHandle(error)
			toast.error("Something went wrong, please contact Administrator!")
		});

	}

	return (
		<>
			<div class="page-header d-print-none mt-3">
				<div class="container-xl">
					<div class="row g-2 align-items-center">
						<div className="container-xl mt-4">
							<div class="row g-2 align-items-center">
								<div class="col">
									<h2 class="page-title">
										Invoice Details
									</h2>
								</div>
								<div class="col-auto ms-auto d-print-none">
									<div class="d-flex justify-content-end ">
										<ol class="breadcrumb breadcrumb-arrows" aria-label="breadcrumbs">
											<li class="breadcrumb-item"><Link to={"/"} >Dashboard</Link></li>
											<li class="breadcrumb-item"><Link to={"/clients"} >Clients</Link></li>
											<li class="breadcrumb-item active" aria-current="page"><Link>Invoice Detail</Link></li>
										</ol>
									</div>
								</div>
							</div>
						</div>
						<div class="col-auto ms-auto d-print-none">
							<button type="button" class="btn btn-primary"
								onClick={() => window.print()}
							>
								<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" /><path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" /><path d="M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z" /></svg>
								Print Invoice
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="page-body">
				<div class="container-xl">
					<div class="card card-lg">
						<div class="card-body">
							<div class="row">
								<div class="col-6">
									<p class="h3">Token Lelo</p>
									<address>
										F411, Titanium City Centre,<br />
										Next to Sachin Towers, Satelite,<br />
										Close to Prahaladnagar,<br />
										Ahmedabad, 380015<br />
										hello@tokenlelo.com
									</address>
								</div>
								<div class="col-6 text-end">
									<p class="h3">{viewClient.name}</p>
									<address>
										{/* {viewClient.name}<br/> */}
										{viewClient.address ? viewClient.address : ""}{viewClient.address && <br />}
										{viewClient.phoneNumber}<br />
										{viewClient.email === "undefined" ? "" : viewClient.email}
									</address>
								</div>
								<div class="col-12 my-5">
									<h1>Invoice INV/0{subscriptionIdDetail.planId}/{subscriptionIdDetail.subscriptionId}</h1>
								</div>
							</div>
							<table class="table table-transparent table-responsive">
								<thead>
									<tr>
										<th className="text-center" style={{ width: '1%' }}></th>
										<th>Plan Name</th>
										<th className="text-start ps-0">SubScription Type</th>
										<th className="text-start ps-0" >Start Date</th>
										<th className="text-start ps-0" >End Date</th>
										<th className="text-start ps-0">Payment Mode</th>
										{subscriptionIdDetail.paymentMode === "Online" &&
											<th className="text-start ps-0">Transaction Id</th>
										}
										<th className="text-end pe-0" >Amount</th>
									</tr>
								</thead>
								<tr>
									<td class="text-center"></td>
									<td>
										<p class="strong mb-1">{subscriptionIdDetail.planName}</p>
										{/* <div class="text-muted">Logo and business cards design</div> */}
									</td>
									<td class="text-start"> {subscriptionIdDetail.subScriptionType ? subscriptionIdDetail.subScriptionType : "-"} </td>
									<td class="text-start">{subscriptionIdDetail.startDateTimeUTC ? moment(subscriptionIdDetail.startDateTimeUTC).format("DD-MMM-YYYY") : "-"}</td>

									<td class="text-start">
										{subscriptionIdDetail.endDateTimeUTC ? moment(subscriptionIdDetail.endDateTimeUTC).format("DD-MMM-YYYY") : "-"}
									</td>
									<td class="text-start"> {subscriptionIdDetail.paymentMode ? subscriptionIdDetail.paymentMode : "-"}</td>
									{subscriptionIdDetail.paymentMode === "Online" &&
										<td class="text-start"> {subscriptionIdDetail.transactionId ? subscriptionIdDetail.transactionId : "-"}</td>}
									<td class="text-end"> ₹ {subscriptionIdDetail.price ? (subscriptionIdDetail.price).toLocaleString('en-IN') : "-"} </td>
								</tr>
								<tr>
									<td class="text-start"></td>
									<td class="text-start"></td>
									<td colspan="4" class="font-weight-bold fw-bold text-uppercase text-end pe-0 me-0">Subtotal</td>
									<td class="font-weight-bold text-end"> ₹ {subscriptionIdDetail.price ? (subscriptionIdDetail.price).toLocaleString('en-IN') : "-"} </td>
								</tr>
								<tr>
									{subscriptionIdDetail.paymentMode === "Online" &&
										<td class="text-start"></td>}
									<td class="text-start"></td>
									<td class="text-start"></td>
									<td colspan="4" class="font-weight-bold fw-bold text-uppercase text-end pe-0 me-0">Discount Amount</td>
									<td class="font-weight-bold text-end"> ₹ {((subscriptionIdDetail.price ? subscriptionIdDetail.price : 0) - (subscriptionIdDetail.paidAmount ? subscriptionIdDetail.paidAmount : 0)).toLocaleString('en-IN')} </td>
								</tr>
								<tr>
									<td class="text-start"></td>
									<td class="text-start"></td>
									<td colspan="4" class="font-weight-bold fw-bold text-uppercase text-end pe-0 me-0">Grand Total</td>
									<td class="font-weight-bold text-end"> ₹ {subscriptionIdDetail.paidAmount ? (subscriptionIdDetail.paidAmount).toLocaleString('en-IN') : "-"}</td>
								</tr>
							</table>
							<p class="text-muted text-center mt-5">Thank you very much for doing business with us. We look forward to serve you better!</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ClientInvoice