import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard.js";
import Categories from "../Categories/Categories.js";
import AddCategory from "../Categories/AddCategory.js";
import Clients from "../Clients/Clients.js";
import SignIn from "../Auth/SignIn.js";
import ForgetPassword from "../Auth/ForgetPassword.js";
import ResetPassword from "../Auth/ResetPassword.js";
import ClientsDetail from "../Clients/ClientDetail.js";
import DailyTokens from "../Users/DailyTokens.js";
import Profile from "../Profile/Profile.js";
import SidebarLayout from "../Common/SidebarLayout.js";
import ChangeAdminPassWord from "../Auth/ChangeAdminPassWord.js";
import ClientInvoice from "../Clients/ClientInvoice.js";
import Customers from "../Users/Customers.js";
import OutletRequestList from "../OutletRequest/OutletRequestList.js";
import CustomerDetail from "../Users/CustomerDetail.js";
import ReviewList from "../Reviews/ReviewList.js";
import Plans from "../Plans/PlanList.js";
import AddPlan from "../Plans/AddPlan.js";

const FormRoute = () => {
  return (
    <Routes>
      <Route path="/forget-password/:id" element={<ResetPassword />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/forget-password" element={<ForgetPassword />} />

      <Route element={<SidebarLayout />}>
      <Route path="/" element={<Dashboard />} />
      <Route path="/change-admin-password" element={<ChangeAdminPassWord/>} />
      {/* Categories start */}
      <Route path="/categories" element={<Categories />} />
      <Route path="/add-category" element={<AddCategory />} />
      <Route path="/update-category/:id" element={<AddCategory />} />
      {/* Categories end */}
      <Route path="/clients" element={<Clients />} />
      <Route path="/client/:id" element={<ClientsDetail />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/customer/:id" element={<CustomerDetail />} />
      <Route path="/daily-tokens" element={<DailyTokens />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/invoice" element={<ClientInvoice />} />
      <Route path="/outlet-requests" element={<OutletRequestList />} />
      <Route path="/reviews" element={<ReviewList />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/add-plan" element={<AddPlan />} />
      <Route path="/update-plan/:id" element={<AddPlan />} />
      </Route>
  
    </Routes>
  );
};

export default FormRoute;
