import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeMenuAction } from "../../actions/index";
import moment from "moment/moment";
import { showLoaderAction } from "../../actions/index";
import SpinerLoader from "../SpinerLoader/spinerLoader";
import defaultOutlet from "../../assests/img/outlet-default.png";
import { useCatchHandler } from "../../utils/utilities"
import { DatePicker } from "antd";
import toast from "react-hot-toast";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import $ from "jquery";
import ClientQrDetails from "./ClientQrDetails";
import DailyTokenList from "../Users/DailyTokenList";
const { REACT_APP_MY_ENV, REACT_APP_WEBAPP_URL } = process.env;

const ClientsDetail = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  let token = localStorage.getItem("adminToken");
  const catchHandle = useCatchHandler();
  const [logoFilePath, setLogoFilePath] = useState("");
  const [file, setFile] = useState(undefined);
  const [img, setImg] = useState("");

  const [formData, setFormData] = useState({
    categoryId: '',
    name: '',
    categoryName: '',
    openTime: '',
    closeTime: '',
    status: 1,
    outletStatus: 1,
    email: '',
    phoneNumber: '',
    address: '',
    approxServeMins: '',
    tokenCost: '',
    agentCode: '',
    ratings: '',
    logoFilePathURL: '',
    logoFileName: '',
    logoFilePath: undefined,
    noOfDevice: '',
    featureOrder: 0,
    anniversaryNotificationEnabled: 1,
    birthdayNotificationEnabled: 1,
    encryptedLoginId: '',
    showFilters: false ,
    EnableMenu: false,
    smsNotificationEnabled: false,
    feedbackNotificationEnabled: false
  });

  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [clientDetails, setClientDetails] = useState("");
  const [serviceDetails, setServiceDetails] = useState([]);
  const [clientUpiList, setClientUpiList] = useState([]);
  const [planId, setPlanId] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [paymentMode, setPaymentMode] = useState("");
  const [transactionId, setTransctionId] = useState("");
  const [price, setPrice] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [planStartDate, setPlanStartDate] = useState("");
  const [planEndDate, setPlanEndDate] = useState("");
  const [ClientId] = useState(location.state?.ClientId);
  const [ClientSlug] = useState(location.state?.ClientSlug);
  const [planList, setPlanList] = useState([]);
  const [isService, setIservice] = useState(false);

  const [planError, setPlanError] = useState("");
  const [paymentModeError, setPaymentModeError] = useState("");
  const [transactionIdError, setTransctionIdError] = useState(1);
  const [priceError, setPriceError] = useState("");
  const [planStartDateError, setPlanStartDateError] = useState("");
  const [planEndDateError, setPlanEndDateError] = useState("");
  const [agentCodeError, setAgentCodeError] = useState({});
  const [logoFileImgError, setLogoFileImgError] = useState({});
  const [phoneNumberError, setPhoneNumberError] = useState({});
  const [paidAmountError, setPaidAmountError] = useState({});
  const [outletNameErr, setOutletNameErr] = useState({});
  const [noOfDeviceErr, setNoOfDeviceErr] = useState({});
  const [approxServeMinsErr, setApproxServeMinsErr] = useState({});

  useEffect(() => {
    getCategoryList()
    // getPlanList()
    if (ClientSlug) {
      GetClientsDetail()
    }
    dispatch(activeMenuAction('client-detail-list'));
    if (clientDetails.length <= 0 && ClientId) {
      getClientSubscriptionDetail();

    }
    if (clientUpiList.length <= 0) {
      getClientUpiList();
    }
  }, []);

  const formValidation = () => {
    let priceError = {};
    let paymentModeError = {};
    let planError = {};
    let paidAmountError = {};
    let transactionIdError = {};
    let planStartDateError = {};
    let planEndDateError = {};
    let isValid = true;

    if (!planId) {
      planError.empty = `Please select  ${isService ? "Service" : "Plan"}`;
    }

    if (!paidAmount) {
      paidAmountError.empty = "Please enter paid amount";
      isValid = false;
    }
    if (!paymentMode) {
      paymentModeError.empty = "Please select payment mode";
      isValid = false;
    }
    else if (paymentMode === "Online") {
      if (!transactionId) {
        transactionIdError.empty = "Please enter transaction Id";
        isValid = false;
      }
    }
    if (!planStartDate) {
      planStartDateError.empty = "Please select start date";
      isValid = false;
    }
    if (!planEndDate) {
      planEndDateError.empty = "Please select end date";
      isValid = false;
    }
    setPaidAmountError(paidAmountError);
    setPaymentModeError(paymentModeError);
    setPlanStartDateError(planStartDateError)
    setPlanEndDateError(planEndDateError)
    setTransctionIdError(transactionIdError)
    setPlanError(planError)
    return isValid;
  }

  const ClientValidation = () => {
    let outlatNameError = {};
    let phoneNumberError = {};
    let emailAddressError = {};
    let categoryErr = {};
    let logoFileImgError = {};
    let noOfDeviceError = {};
    let approxServeMinsError = {};


    let agentCodeError = {};
    let isValid = true;

    if (!formData.name) {
      outlatNameError.nameEmpty = "Please enter name";
      isValid = false;
    }

    if (!formData.phoneNumber) {
      phoneNumberError.phoneNumberEmpty = "Please enter phone number";
      isValid = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(formData.phoneNumber)) {
      phoneNumberError.phoneInvalid =
        "Please enter valid phone number";
      isValid = false;
    }
    if (!formData.noOfDevice) {
      noOfDeviceError.noOfDeviceEmpty = "Please enter no of device";
      isValid = false;
    }

    if (!formData.approxServeMins) {
      approxServeMinsError.nameEmpty = "Please enter Approx Serve Minutes";
      isValid = false;
    } else if (parseInt(formData.approxServeMins) <= 0) {
      approxServeMinsError.approxMinEmpty = "Approx Serve Minutes should be greated than zero(0)";
      isValid = false;
    }

    // if (!formData.logoFilePath) {
    //   logoFileImgError.FileImgErrorEmpty = "Please upload a profile photo";
    //   isValid = false;
    // } else {
    //   const allowedExtensions = ["jpg", "jpeg", "png", "svg"];
    //   const fileExtension = (formData.logoFilePath.name || "").split(".").pop().toLowerCase();
    //   if (!allowedExtensions.includes(fileExtension)) {
    //     logoFileImgError.FileImgInvalid = "Invalid file extension. Please upload a file with extensions jpg, jpeg, png, or svg";
    //     isValid = false;
    //   }
    // }

    if (!formData.agentCode) {
      agentCodeError.empty = "Please enter agent code";
      isValid = false;
    }

    setApproxServeMinsErr(approxServeMinsError)
    setNoOfDeviceErr(noOfDeviceError)
    setOutletNameErr(outlatNameError);
    setPhoneNumberError(phoneNumberError);
    setLogoFileImgError(logoFileImgError)
    setAgentCodeError(agentCodeError);
    return isValid;
  };

  const getCategoryList = async () => {
    const response = await axios.get(REACT_APP_MY_ENV + "/category-list").then(function (response) {
      if (response.data.status === 200) {
        setCategoryList(response.data.data.categoryList)
      } else {
        toast.error(response.data.message);
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    })
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    const isDecimalPoint = charCode === 46;

    if (
      (charCode < 48 || charCode > 57) &&
      !isDecimalPoint
    ) {
      e.preventDefault();
    }

    if (isDecimalPoint && price.includes('.')) {
      e.preventDefault();
    }
  };

  const getClientSubscriptionDetail = async () => {
    let data = {
      ClientId: (ClientId).toString() ? (ClientId).toString() : ""
    }
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/client-subscription-list", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        setClientDetails(response.data.data.planList)
        setServiceDetails(response.data.data.serviceList)
      } else {
        dispatch(showLoaderAction(false));
        setClientDetails([]);
      }
    }).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
    dispatch(showLoaderAction(false));
  }

  const GetClientsDetail = async (dataSlug) => {
    const data = {
      isView: true,
      slug: ClientSlug ? ClientSlug : dataSlug
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/outlet/", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        if (response.data.data) {
          // setViewClient(response.data.data.outlet)
          setFormData(response.data.data.outlet)
          setLogoFilePath(response.data.data.outlet.logoFilePath)
          setImg(response.data.data.outlet.logoFilePathURL || defaultOutlet)
        }
      } else {
        dispatch(showLoaderAction(false));
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
  };

  const handleClick = async (isService) => {
    // e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      let datas = {
        clientId: (ClientId).toString(),
        planId: planId,
        startDateTimeUTC: planStartDate,
        endDateTimeUTC: planEndDate,
        paymentMode: paymentMode,
        transactionId: transactionId,
        status: "Active",
        paidAmount: paidAmount,
        IsServieTrue: isService
      };
      const response = await axios.post(REACT_APP_MY_ENV + "/add-subscription", datas, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          $("#openSubscriptionModal").trigger("click");
          setPlanError({})
          setPrice("")
          setPlanId("")
          setTransctionId("")
          setPaidAmount("")
          setPlanEndDate("")
          setPlanStartDate("")
          setPlanList([])
          setPaymentMode('')
          getClientSubscriptionDetail()
        } else {
          toast.error(response.data.message);
        }
      }).catch(function (error) {
        catchHandle(error)
        toast.error("Something went wrong, please contact Administrator!")
      });
    }
  };

  const getPlanList = async (IsServie) => {
    debugger
    let data = {
      IsServieTrue: IsServie ? IsServie : false
    }
    const response = await axios.post(REACT_APP_MY_ENV + "/plan-list",data).then(function (response) {
        if (response.data.status === 200) {
          setPlanList(response.data.data.planList)
          setIservice(IsServie)
        } else {
          toast.error(response.data.message);
        }
      }).catch(function (error) {
        toast.error("Something went wrong, please contact Administrator!")
        catchHandle(error)
      });
  }

  const StartDateHandleChange = (date, dateSting) => {
    setPlanStartDate(dateSting);
  };
  const EndDateHandleChange = (date, dateSting) => {
    setPlanEndDate(dateSting);
  };

  const HandleChange = (e) => {

    if (e.target.name === 'plan') {
      setPlanId(e.target.value)
    }
    if (e.target.name === 'paymentMode') {
      setPaymentMode(e.target.value)
    }
    if (e.target.name === 'price') {
      setPrice(e.target.value)
    }
    if (e.target.name === 'transactionId') {
      setTransctionId(e.target.value)
    }
    if (e.target.name === 'paidAmount') {
      setPaidAmount(e.target.value)
    }
  };

  const disabledDate = (current) => {
    return current && current.isBefore(dayjs(), 'day');
  };

  const getClientUpiList = async () => {
    let data = {
      ClientId: ClientId ? (ClientId).toString() : "",
      IsAdmin: true
    }
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/get-client-upi-id", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        setClientUpiList(response.data.data.clientUpiList)

      } else {
        dispatch(showLoaderAction(false));
        setClientUpiList([]);
      }
    }).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
    dispatch(showLoaderAction(false));
  }

  const handlePrintInvoice = (subscriptionId) => {
    const newTab = window.open(
      `/invoice?slug=${formData.slug}&subscriptionId=${subscriptionId}`,
      "_blank"
    );
    if (newTab) {
      newTab.focus();
    } else {
      console.error("Unable to open new tab");
    }
  };

  const onClientUpdate = async () => {
    if (ClientValidation()) {
      const data = new FormData();
      data.append("IsAdmin", true)
      data.append("EncryptedClientId", ClientId)
      data.append("Name", formData.name);
      data.append("PhoneNumber", formData.phoneNumber);
      data.append("Address", formData.address);
      data.append("ApproxServeMins", formData.approxServeMins);
      data.append("Email", formData.email);
      data.append("OpenTime", formData.openTime);
      data.append("CloseTime", formData.closeTime);
      data.append("LogoFilePath", file);
      data.append("LogoFileName", logoFilePath)
      data.append("Status", formData.status);
      data.append("outletStatus", formData.outletStatus);
      data.append("AgentCode", formData.agentCode);
      data.append("CategoryId", formData.categoryId);
      data.append("noOfDevice", formData.noOfDevice);
      data.append("FeatureOrder", formData.featureOrder === null ? 0 : formData.featureOrder);
      data.append("SendSMS", formData.smsNotificationEnabled ? formData.smsNotificationEnabled : false);
      data.append("TokenCost", formData.tokenCost === null ? 0 : formData.tokenCost);
      data.append("AnniversaryNotificationEnabled", formData.anniversaryNotificationEnabled === null ? 0 : formData.anniversaryNotificationEnabled);
      data.append("BirthdayNotificationEnabled", formData.birthdayNotificationEnabled === null ? 0 : formData.birthdayNotificationEnabled);
      data.append("ShowFilters", formData.showFilters ? formData.showFilters : false);
      data.append("EnableMenu", formData.enableMenu ? formData.enableMenu: false);
      data.append("FeedbackNotificationEnabled", formData.feedbackNotificationEnabled ? formData.feedbackNotificationEnabled: false);
      dispatch(showLoaderAction(true));
      const res = await axios.post(REACT_APP_MY_ENV + "/client-update-profile", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" } }).then(function (res) {
        if (res.data.status === 200) {
          dispatch(showLoaderAction(false));
          toast.success(res.data.message);

        } else {
          dispatch(showLoaderAction(false));
          toast.error(res.data.message);
        }
      }).catch(function (error) {
        catchHandle(error, "false")
        dispatch(showLoaderAction(false));
        toast.error("Something went wrong, please contact Administrator!")
      });
    }
  };

  const handleClientFiledChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleUploadFileChange = async (e) => {
    setImg(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const handleStatusFieldChange = (e) => {
    if (e.target.name === "outletStatus") {
      setFormData({
        ...formData,
        outletStatus: e.target.checked ? 1 : 0,

      });
    }
    else {
      setFormData({
        ...formData,
        status: e.target.checked ? 1 : 0,

      });
    }
  };
  const handleNotificationStatusChange = (e) => {
    if (e.target.name === "birthdayNotification") {
      setFormData({
        ...formData,
        birthdayNotificationEnabled: e.target.checked ? 1 : 0,

      });
    }
    else if(e.target.name === "anniversaryNotification") {
      setFormData({
        ...formData,
        anniversaryNotificationEnabled: e.target.checked ? 1 : 0,

      });
    }else{
      setFormData({
        ...formData,
        smsNotificationEnabled: e.target.checked  ? true : false,

      });
    }
  };

  const handleShowFilterFieldChange = (e) => {
    if (e.target.name === "showFilters") {
      setFormData({
        ...formData,
        showFilters: e.target.checked ? true : false,
      });
    }
    else {
      setFormData({
        ...formData,
        showFilters: e.target.checked ? true : false,
      });
    }
  }

  const handleEnableMenuFieldChange = (e) => {
    if (e.target.name === "enableMenu") {
      setFormData({
        ...formData,
        enableMenu: e.target.checked ? true : false,
      });
    }
    else {
      setFormData({
        ...formData,
        enableMenu: e.target.checked ? true : false,
      });
    }
  }

  const handleFeedbackNotificationEnabledFieldChange = (e) => {
    if (e.target.name === "feedbackNotificationEnabled") {
      setFormData({
        ...formData,
        feedbackNotificationEnabled: e.target.checked ? true : false,
      });
    }
    else {
      setFormData({
        ...formData,
        feedbackNotificationEnabled: e.target.checked ? true : false,
      });
    }
  }

  const clearForm = e => {
    setPlanList([])
    setPlanId("")
    setPrice("")
    setTransctionId("")
    setPaidAmount("")
    setPlanEndDate("")
    setPlanStartDate("")
    setPaymentMode('')
    setPaidAmountError({})
    setPaymentModeError({});
    setPriceError({});
    setPlanStartDateError({})
    setPlanEndDateError({});
    setTransctionIdError({});
    setPlanError({})
  }

  const loginAsOutlet = (loginId) => {
    window.open(`${REACT_APP_WEBAPP_URL}?outletlogin=${encodeURIComponent(loginId)}`, '_blank');
  }

  return (
    <>
      {showLoader && (<SpinerLoader />)}
   
      <div className="page-wrapper">
        <div className="container-xl mt-4">
          <div class="row g-2 align-items-center">
            <div class="col">
              <h2 class="page-title">
                Client Details
              </h2>
            </div>
            <div class="col-auto ms-auto d-print-none">
              <div class="d-flex justify-content-end ">
                <ol class="breadcrumb breadcrumb-arrows" aria-label="breadcrumbs">
                <li class="breadcrumb-item"><Link to={"/"} >Dashboard</Link></li>
                  <li class="breadcrumb-item"><Link to={"/clients"} >Clients</Link></li>
                  <li class="breadcrumb-item active" aria-current="page"><Link>Client Detail</Link></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="page-header m-0 d-print-none">
          <div className="container-xl">
            <div className="row g-2 align-items-center">
            </div>
          </div>
        </div>
        <div className="page-body mt-0">
          <div className="container-xl">
            <div className="card">
              <div className="col-auto ms-auto d-print-none">
                <div className="d-flex">                  
                  <button className="btn btn-primary mt-2 me-2" onClick={() => loginAsOutlet(formData.encryptedLoginId)}>
                    Login As Outlet
                  </button>
                </div>
              </div>
              <div className="row g-0" >
                <div className="col d-flex flex-column" >
                  <div className="card-body" >
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <img className="avatar avatar-lg"
                          name="logoFilePathURL"
                          src={img}
                          onError={(error) => { error.target.src = defaultOutlet }}
                          alt="Profile" />
                        {/* {Object.keys(photoFileError).map((key) => {
                    return (
                      <p className="text-danger">
                        {photoFileError[key]}
                      </p>
                    );
                  })} */}
                      </div>
                      <div className="col-auto">
                        <label className="btn">
                          Change Logo
                          <input
                            type="file"
                            name="logoFilePathURL"
                            onChange={handleUploadFileChange}
                            autoComplete="off"
                            accept=".jpg, .jpeg, .png, image/heif"
                            style={{ display: "none" }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Outlet Name</div>
                        <input type="text" className="form-control"
                          value={formData.name}
                          onChange={handleClientFiledChange}
                          name="name" />
                        {Object.keys(outletNameErr).map((key) => {
                          return (
                            <p className="text-danger">
                              {outletNameErr[key]}
                            </p>
                          );
                        })}
                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Category</div>
                        <select
                          name="categoryId"
                          class="form-control"
                          placeholder=""
                          onChange={handleClientFiledChange}
                          value={formData.categoryId}
                        >
                          {categoryList.map((category) => (
                            <option key={category.categoryId} value={category.categoryId}>
                              {category.categoryName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Open Time</div>
                        <input type="time" className="form-control"
                          value={formData.openTime}
                          onChange={handleClientFiledChange}
                          name="openTime"
                        />
                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Close Time</div>
                        <input type="time" className="form-control"
                          value={formData.closeTime}
                          onChange={handleClientFiledChange}
                          name="closeTime"
                        />
                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Email</div>
                        <input type="text" className="form-control"
                          value={formData.email === "undefined" ? "" : formData.email}
                          onChange={handleClientFiledChange}
                          name="email" />
                        {/* {Object.keys(emailAddressError).map((key) => {
                          return (
                            <p className="text-danger">
                              {emailAddressError[key]}
                            </p>
                          );
                        })} */}
                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Phone Number</div>
                        <input type="text" className="form-control"
                          value={formData.phoneNumber}
                          maxLength={10}
                          onKeyPress={(e) => {
                            const isNumeric = /^[0-9\b]+$/.test(e.key);

                            if (!isNumeric) {
                              e.preventDefault();
                            }
                          }}
                          onChange={handleClientFiledChange}
                          name="phoneNumber" />
                        {Object.keys(phoneNumberError).map((key) => {
                          return (
                            <p className="text-danger">
                              {phoneNumberError[key]}
                            </p>
                          );
                        })}
                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Address</div>
                        <textarea id="address"
                          rows="2"
                          cols="2"
                          name="address"
                          autocomplete="off"
                          value={formData.address === "null" ? "": formData.address}
                          maxLength="500"
                          placeholder="Enter Address"
                          onChange={handleClientFiledChange}
                          class="form-control" />

                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Approx Serve Minutes</div>
                        <input type="text" className="form-control"
                          value={formData.approxServeMins}
                          maxLength={10}
                          onKeyPress={(e) => {
                            const isNumeric = /^[0-9\b]+$/.test(e.key);

                            if (!isNumeric) {
                              e.preventDefault();
                            }
                          }}
                          onChange={handleClientFiledChange}
                          name="approxServeMins" />
                        {Object.keys(approxServeMinsErr).map((key) => {
                          return (
                            <p className="text-danger">
                              {approxServeMinsErr[key]}
                            </p>
                          );
                        })}
                      </div>

                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Token Cost</div>
                        <input type="number" className="form-control"
                          value={formData.tokenCost}
                          onChange={handleClientFiledChange}
                          name="tokenCost" />
                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Agent Code</div>
                        <input type="text" className="form-control"
                          value={formData.agentCode}
                          maxLength={50}
                          onChange={handleClientFiledChange}
                          name="agentCode" />
                        {Object.keys(agentCodeError).map((key) => {
                          return (
                            <p className="text-danger">
                              {agentCodeError[key]}
                            </p>
                          );
                        })}
                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">No Of Device</div>
                        <input type="text" className="form-control"
                          value={formData.noOfDevice}
                          maxLength={6}
                          onKeyPress={(e) => {
                            const isNumeric = /^[0-9\b]+$/.test(e.key);

                            if (!isNumeric) {
                              e.preventDefault();
                            }
                          }}
                          onChange={handleClientFiledChange}
                          name="noOfDevice" />
                        {Object.keys(noOfDeviceErr).map((key) => {
                          return (
                            <p className="text-danger">
                              {noOfDeviceErr[key]}
                            </p>
                          );
                        })}
                      </div>
                      <div className="col-md-2 me-2">
                        <div className="form-label mt-4">Feature Order</div>
                        <input type="text" className="form-control"
                          value={formData.featureOrder}
                          maxLength={6}
                          onKeyPress={(e) => {
                            const isNumeric = /^[0-9\b]+$/.test(e.key);

                            if (!isNumeric) {
                              e.preventDefault();
                            }
                          }}
                          onChange={handleClientFiledChange}
                          name="featureOrder" />
                      </div>
                      <div className="col-md-2">
                        <div className="form-label  mt-4">Outlet Status</div>
                        <label className="form-check form-check-single form-switch">
                          <input className="form-check-input" type="checkbox" name="outletStatus"
                            checked={formData.outletStatus === 1}
                            onChange={handleStatusFieldChange}
                          // onClick={() => onClientUpdate(formData.outletStatus)}
                          />
                        </label>
                      </div>
                      <div className="col-md-2">
                        <div className="form-label mt-4 ">Status</div>
                        <label className="form-check form-check-single form-switch">
                          <input className="form-check-input" type="checkbox" name="Status"
                            checked={formData.status === 1}
                            onChange={handleStatusFieldChange}
                          // onClick={() => onClientUpdate(formData)} 
                          />
                        </label>
                      </div>
                      <div className="col-md-2  ">
                        <div className="form-label  ms-2 mt-4">Birthday Notification</div>
                        <label className="form-check form-check-single form-switch">
                          <input className="form-check-input" type="checkbox" name="birthdayNotification"
                            checked={formData.birthdayNotificationEnabled === 1}
                            onChange={handleNotificationStatusChange}
                          />
                        </label>
                      </div>
                      <div className="col-md-2  ">
                        <div className="form-label  mt-4 ms-3">Anniversary Notification</div>
                        <label className="form-check form-check-single form-switch">
                          <input className="form-check-input" type="checkbox" name="anniversaryNotification"
                            checked={formData.anniversaryNotificationEnabled === 1}
                            onChange={handleNotificationStatusChange}
                          />
                        </label>
                      </div>
                      <div className="col-md-2  ">
                        <div className="form-label  mt-4 ms-3">SMS Notification</div>
                        <label className="form-check form-check-single form-switch">
                          <input className="form-check-input" type="checkbox" name="smsNotification"
                            checked={formData.smsNotificationEnabled  === true}
                            onChange={handleNotificationStatusChange}
                          />
                        </label>
                      </div>
                      <div className="col-md-2">
                        <div className="form-label  ms-3 mt-4">Show Filters</div>
                        <label className="form-check form-check-single form-switch">
                          <input className="form-check-input" type="checkbox" name="showFilters"
                            checked={formData.showFilters === true}
                            onChange={handleShowFilterFieldChange}
                          />
                        </label>
                      </div>
                      <div className="col-md-2">
                        <div className="form-label  ms-3 mt-4">Enable Menu</div>
                        <label className="form-check form-check-single form-switch">
                          <input className="form-check-input" type="checkbox" name="enableMenu"
                            checked={formData.enableMenu === true}
                            onChange={handleEnableMenuFieldChange}
                          />
                        </label>
                      </div>
                      <div className="col-md-2">
                        <div className="form-label  ms-3 mt-4">Feedback Notification</div>
                        <label className="form-check form-check-single form-switch">
                          <input className="form-check-input" type="checkbox" name="feedbackNotificationEnabled"
                            checked={formData.feedbackNotificationEnabled === true}
                            onChange={handleFeedbackNotificationEnabledFieldChange}
                          />
                        </label>
                      </div>
                      <div className="col-md-2  ms-3 mt-4">
                        <div className="form-label">Ratings</div>
                        <p className="card-subtitle ">{formData.ratings ? formData.ratings : "No Review"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer bg-transparent mt-auto">
                    <div className="btn-list justify-content-end">
                      <button className="btn btn-primary" onClick={onClientUpdate} >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DailyTokenList clientId={ClientId} outletName ={formData.name}/>
            <div className="col mt-4 d-flex" >
              <h2 className="page-title" data-bs-toggle="modal"
                data-bs-target="#modal-team"
                id="openSubscriptionModal">
                Subscriptions
              </h2>
              <div className="ms-auto">
                {clientDetails.length <= 0 || clientDetails.every(detail => detail.status === "Expired") ?
                  <Link to="" className="btn" data-bs-toggle="modal" data-bs-target="#modal-team" onClick={() => { getPlanList(false); }} >
                    Add Subscription
                  </Link>
                  : ""}
              </div>
            </div>
            <div className="row row-cards  mt-4">
              <div className="d-flex">
                {clientDetails.length > 0 ? clientDetails && clientDetails.map((clientDetail, index) => {
                  return (
                    <div className="col-sm-6 col-lg-3 me-2">
                      <div className="card card-md">
                        <div className={` ${clientDetail.status === "Active" ? "ribbon ribbon-top ribbon-bookmark bg-green" : ""}`}>
                          {clientDetail.status === "Active" ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg> : ""}
                        </div>
                        <div className="card-body text-center" >
                          <div className="text-uppercase text-muted font-weight-medium">{clientDetail.planName}</div>
                          <div className="display-6 fw-bold my-3">
                            <span>&#8377;</span>{(clientDetail.price).toLocaleString('en-IN')}/{clientDetail.subScriptionType}</div>
                          <ul className="list-unstyled lh-lg">
                            <li>From: {clientDetail.startDateTimeUTC ? moment(clientDetail.startDateTimeUTC).format("D, MMM YYYY") : ""}</li>
                            <li>To: {clientDetail.endDateTimeUTC ? moment(clientDetail.endDateTimeUTC).format("D, MMM YYYY") : ""}</li>
                            <li>Payment Mode: {clientDetail.paymentMode}</li>
                            <li>{clientDetail.transactionId === "undefined" ? "" : clientDetail.transactionId ? clientDetail.transactionId : ""}</li>
                          </ul>
                          <div className="text-center mt-4">
                            <p className={`btn w-100${clientDetail.status === "Active" ? "btn btn-green w-100" : ""}`} >{clientDetail.status === "Active" ? "Activated" : "Expired"}</p>
                          </div>
                          <Link onClick={() => handlePrintInvoice(clientDetail.subscriptionId)} state={{ viewClient: formData, clientDetails: clientDetail }} className="btn btn-invoice btn-primary" >
                            Print Invoice
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                }) : <span><p>No subscription yet!</p></span>}
              </div>
            </div>
            <div className="col mt-4 d-flex" >
              <h2 className="page-title" data-bs-toggle="modal"
                data-bs-target="#modal-service"
                id="openServiceModal">
                Add-on Services
              </h2>
              <div className="ms-auto">
                <Link to="" className="btn " data-bs-toggle="modal" data-bs-target="#modal-team" onClick={() => { getPlanList(true); }}>
                  Add Service
                </Link>
              </div>
            </div>
            <div className="row row-cards  mt-4">
              <div className="d-flex">
                {serviceDetails.length > 0 ? serviceDetails && serviceDetails.map((clientDetail, index) => {
                  return (
                    <div className="col-sm-6 col-lg-3 me-2">
                      <div className="card card-md">
                        <div className={` ${clientDetail.status === "Active" ? "ribbon ribbon-top ribbon-bookmark bg-green" : ""}`}>
                          {clientDetail.status === "Active" ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg> : ""}
                        </div>
                        <div className="card-body text-center" >
                          <div className="text-uppercase text-muted font-weight-medium">{clientDetail.planName}</div>
                          <div className="display-6 fw-bold my-3">
                            <span>&#8377;</span>{(clientDetail.price).toLocaleString('en-IN')}/{clientDetail.subScriptionType}</div>
                          <ul className="list-unstyled lh-lg">
                            <li>From: {clientDetail.startDateTimeUTC ? moment(clientDetail.startDateTimeUTC).format("D, MMM YYYY") : ""}</li>
                            <li>To: {clientDetail.endDateTimeUTC ? moment(clientDetail.endDateTimeUTC).format("D, MMM YYYY") : ""}</li>
                            <li>Payment Mode: {clientDetail.paymentMode}</li>
                            <li>{clientDetail.transactionId === "undefined" ? "" : clientDetail.transactionId ? clientDetail.transactionId : ""}</li>
                          </ul>
                          <div className="text-center mt-4">
                            <p className={`btn w-100${clientDetail.status === "Active" ? "btn btn-green w-100" : ""}`} >{clientDetail.status === "Active" ? "Activated" : "Expired"}</p>
                          </div>
                          <Link onClick={() => handlePrintInvoice(clientDetail.subscriptionId)} state={{ viewClient: formData, clientDetails: clientDetail }} className="btn btn-invoice btn-primary" >
                            Get Invoice
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                }) : <span><p>No Service yet!</p></span>}

              </div>
            </div>

            <ClientQrDetails formData={formData} />
          </div>
        </div>
      </div>
      <div className="modal modal-blur fade" id="modal-team" tabindex="-1" role="dialog" aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isService ? 'Create Add-on Service' : '  Add Subscription'}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
            </div>
            <div className="modal-body">
              <div className="row align-items-end">
                <div className="mb-3 col-sm-4 col-md-6">
                  <label className="form-label required">{isService ? "Service" : "Plan"}</label>
                  <select className="form-select" value={planId} name="plan" onChange={HandleChange}>
                    <option value="">Select {isService ? "Service" : "Plan"}</option>
                    {planList.map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan.name}
                      </option>
                    ))}
                  </select>
                  {Object.keys(planError).map((key) => {
                    return (
                      <p className="text-danger">
                        {planError[key]}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className=" row">
                <div className="col-sm-4 col-md-6 mb-3">
                  <div className="input-icon mb-2">
                    <label className="form-label required">Start Date</label>
                    <DatePicker
                      className="form-control"
                      format="YYYY-MM-DD"
                      value={planStartDate ? dayjs(planStartDate, 'YYYY-MM-DD') : null}
                      onChange={StartDateHandleChange}
                      disabledDate={disabledDate}
                      placeholder="Select Start Date"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                    {Object.keys(planStartDateError).map((key) => {
                      return (
                        <p className="text-danger">
                          {planStartDateError[key]}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="col-sm-4 col-md-6 mb-3">
                  <div className="input-icon mb-2">
                    <label className="form-label required">End Date</label>
                    <DatePicker
                      className="form-control"
                      format="YYYY-MM-DD"
                      value={planEndDate ? dayjs(planEndDate, 'YYYY-MM-DD') : null}
                      onChange={EndDateHandleChange}
                      disabledDate={disabledDate}
                      placeholder="Select End Date"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                    {Object.keys(planEndDateError).map((key) => {
                      return (
                        <p className="text-danger">
                          {planEndDateError[key]}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 col-md-6 ">
                  <label className="form-label required" >Payment Mode</label>
                  <select className="form-select" selected={paymentMode} name="paymentMode" onChange={HandleChange}>
                    <option value="">Select Payment Mode</option>
                    <option value="Online">Online</option>
                    <option value="Cash">Cash</option>
                  </select>
                  {Object.keys(paymentModeError).map((key) => {
                    return (
                      <p className="text-danger">
                        {paymentModeError[key]}
                      </p>
                    );
                  })}
                </div>
                <div className=" col-sm-4 col-md-6">
                  <label className="form-label required">Paid Amount</label>
                  <input type="text" onKeyPress={handleKeyPress} className="form-control" value={paidAmount} placeholder="Enter Paid Amount" name="paidAmount" onChange={HandleChange} />
                  {Object.keys(paidAmountError).map((key) => {
                    return (
                      <p className="text-danger">
                        {paidAmountError[key]}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 col-md-6  mb-3">
                  {paymentMode === "Online" && <>
                    <label className="form-label required" >Transaction ID</label>
                    <input type="text" className="form-control" value={transactionId} name="transactionId" onChange={HandleChange} />
                    {Object.keys(transactionIdError).map((key) => {
                      return (
                        <p className="text-danger">
                          {transactionIdError[key]}
                        </p>
                      );
                    })}</>
                  }
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn me-auto" data-bs-dismiss="modal" onClick={clearForm}>Close</button>
              <button type="button" className="btn btn-primary"
                onClick={(e) => handleClick(isService ? true : false)}
              >Submit</button>
            </div>
          </div>
        </div>
      </div>
      </>

  )
}
export default ClientsDetail;
