import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../actions/index";
import SpinerLoader from "../SpinerLoader/spinerLoader";
import { Link, useNavigate } from "react-router-dom";
import { useCatchHandler } from "../../utils/utilities"
const { REACT_APP_MY_ENV } = process.env;

const Profile = () => {
  const [formData, setFormData] = useState({
    name:'',
    email: '',
    profilePhotoFileURL:'',
    logoFileName:'',
    logoFilePath:''
  });
  const [adminDetails, setAdminDetails] = useState({});
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [photoFileError, setPhotoFileError] = useState("");
  const [logoFilePath, setLogoFilePath] = useState("");
  const [file, setFile] = useState(undefined);
  const [img, setImg] = useState("");

  let token = localStorage.getItem("adminToken");
  const catchHandle = useCatchHandler();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let SuperAdminId = localStorage.getItem("encryptedAdminId")
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;

  useEffect(() => {
    const storedName = localStorage.getItem("AdminName");
    if (storedName) {
      setFormData((prevData) => ({
        ...prevData,
        name: storedName,
      }));
    } else if (formData.name) {
      localStorage.setItem("AdminName", formData.name);
    }
    getAdminDetail();
  }, [])

  const formValidation = () => {
    let nameError = {};
    let emailError = {};
    let photoFileError = {};
    let isValid = true;

    if (!formData.name) {
      nameError.empty = "Please enter name";
      isValid = false;
    }
    if (!formData.email) {
      emailError.empty = "Please enter email";
      isValid = false;
    }
    else  if (formData.email) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
        emailError.emailInvalid = "Please enter valid email address";
        isValid = false;
      }
    }

    if (!formData.profilePhotoFileURL) {
      photoFileError.FileImgErrorEmpty = "Please upload a profile photo";
      isValid = false;
    } 
    // else {
    //   const allowedExtensions = ["jpg", "jpeg", "png", "svg"];
    //   const fileExtension = (formData.profilePhotoFileURL.name || "").split(".").pop().toLowerCase();
    //   if (!allowedExtensions.includes(fileExtension)) {
    //     photoFileError.FileImgInvalid = "Invalid file extension. Please upload a file with extensions jpg, jpeg, png, or svg.";
    //     isValid = false;
    //   }
    // }
    
    setNameError(nameError);
    setEmailError(emailError);
    setPhotoFileError(photoFileError)
    return isValid;
  };

  const getAdminDetail = async () => {
    const data = {
      SuperAdminId: localStorage.getItem("encryptedAdminId")
    }
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/get-admin-detail", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        let AdminDetail = response.data.data.adminDetail;
        setFormData(AdminDetail)
        localStorage.setItem('AdminName',AdminDetail.name)
        setLogoFilePath(AdminDetail.profilePhotoFile)
        setImg(AdminDetail.profilePhotoFileURL)
      } else {
        dispatch(showLoaderAction(false));
        setAdminDetails([]);
      }
    }).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
    dispatch(showLoaderAction(false));
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
        const data = new FormData(); 
        data.append("SuperAdminId", SuperAdminId);
        data.append("Name", formData.name);
        data.append("Email", formData.email);
        data.append("ProfilePhotoName", logoFilePath);
        data.append("ProfilePhotoFile", file);
        localStorage.setItem("AdminName", formData.name);
        dispatch(showLoaderAction(true));
        const response = await axios.post(REACT_APP_MY_ENV + "/super-admin-update-profile", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }}).then(function (response) {
        if (response.data.status === 200) {
          dispatch(showLoaderAction(false));
          toast.success(response.data.message);
          navigate("/")
        } else {
          toast.error(response.data.message);
        }}).catch(function (error) {
          catchHandle(error)
          dispatch(showLoaderAction(false));
          toast.error("Something went wrong, please contact Administrator!")
        });
    }
  };
  const handleUploadFileChange = async (e) => {
    setImg(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const HandleChange = e => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value
    })
    if (e.target.name === 'name') {
      localStorage.setItem("AdminName", e.target.value);
    }
  }
  
  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div className="page-body">
        <div className="container-xl">
          <div className="card">
            <div className="row g-0">
              <div className="col d-flex flex-column">
                <div className="card-body">
                  <h2 className="mb-4">My Account</h2>
                  <h3 className="card-title">Profile Details</h3>
                  <div className="row align-items-center">
                    <div className="col-auto">
                    <img className="avatar avatar-xl" src={img} alt="Profile" />
                      {Object.keys(photoFileError).map((key) => {
                    return (
                      <p className="text-danger">
                        {photoFileError[key]}
                      </p>
                    );
                  })}
                    </div>
                    <div className="col-auto">
                      <label className="btn">
                        Change avatar
                        <input
                          type="file"
                          onChange={handleUploadFileChange}
                          // autoComplete="off"
                          // accept=".jpg, .jpeg, .png, image/heif"
                          style={{ display: "none" }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-2 me-3">
                      <div className="form-label mt-4">Name</div>
                      <input type="text" className="form-control" name="name" value={formData.name} onChange={HandleChange}  />
                      {Object.keys(nameError).map((key) => {
                    return (
                      <p className="text-danger">
                        {nameError[key]}
                      </p>
                    );
                  })}
                    </div>
                    <div className="col-md-2">
                    <div className="form-label mt-4">Email</div>
                        <input type="text" className="form-control" name="email" value={formData.email} onChange={HandleChange} />
                        {Object.keys(emailError).map((key) => {
                    return (
                      <p className="text-danger">
                        {emailError[key]}
                      </p>
                    );
                  })}
                      </div>
                  </div>
                </div>
                <div className="card-footer bg-transparent mt-auto">
                  <div className="btn-list justify-content-end">
                    <Link to="" className="btn">
                      Cancel
                    </Link>
                    <button className="btn btn-primary" onClick={onSubmit}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile