import React, { useEffect, useState, useRef } from "react";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import toast from "react-hot-toast";
import axios from "axios";
import * as XLSX from 'xlsx';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeMenuAction } from "../../actions/index";
import { useCatchHandler } from "../../utils/utilities"
import moment from "moment/moment";
import $ from "jquery";
import { showLoaderAction } from "../../actions/index";
import SpinerLoader from "../SpinerLoader/spinerLoader";
const { REACT_APP_MY_ENV } = process.env;

const DailyTokenList = (props) => {
  const currentURL = window.location.href;
  const urlParts = currentURL.split('/');
  const ClientDetailId = urlParts[urlParts.length - 1];
  const dispatch = useDispatch();
  const clientIdProp = props?.clientId?.toString();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [selectedClient, setSelectedClient] = useState(ClientDetailId);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pageCount = Math.max(1, Math.ceil(totalCount / itemsPerPage));
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [tokenUsersList, setTokenUsersList] = useState([]);

  const catchHandle = useCatchHandler();
  useEffect(() => {
    getUsers()
    dispatch(activeMenuAction('daily-token-list'));
  }, []);



  const handlePageClick = (selected) => {
    setCurrentPage(selected);
    getUsers(selectedClient, "", selected, itemsPerPage, date);
  };

  const handlePageNextClick = () => {
    if (currentPage < pageCount) {
      setCurrentPage((prevPage) => prevPage + 1);
      getUsers(selectedClient, "", currentPage + 1, itemsPerPage, date);
    }
  };

  const handlePagePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      getUsers(selectedClient, "", currentPage - 1, itemsPerPage, date);
    }
  };

  const handlePageSizeClick = (e) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value);
    if (e.target.value >= 1) {
      getUsers(selectedClient, "", currentPage, parseInt(e.target.value), date);
    }
    else {
      setItemsPerPage();
    }
  };


  const onSearchResult = (e) => {
    const inputValue = e.target.value;
    if (inputValue !== "") {
      setSearchText(inputValue);
    } else if (inputValue === "") {
      setSearchText('');
      getUsers(selectedClient, "", 1, itemsPerPage, date);
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSearchHandleClick();
    }
  }

  const onSearchHandleClick = (e) => {
    if (searchText) {
      getUsers(selectedClient, searchText, 1, itemsPerPage, date)
    } else {
      setSearchText("")
      getUsers(selectedClient, "", 1, itemsPerPage)
    }
  }

  const PreferredDateHandleChange = (date, dateSting) => {
    setDate(dateSting);
    getUsers(selectedClient, "", currentPage, itemsPerPage, dateSting)
  };

  const getUsers = async (clientId, searchValue, pageNumber, pagesize, searchDate) => {
    const requestData = {
      encryptedClientId: clientId ? clientId : selectedClient,
      pageNumber: pageNumber ? pageNumber : currentPage,
      pageSize: pagesize ? pagesize : itemsPerPage,
      searchText: searchText ? searchText : searchValue ? searchValue : "",
      searchDate: searchDate ? searchDate : date,
      status: ""
    }
    dispatch(showLoaderAction(!searchText));
    const response = await axios.post(REACT_APP_MY_ENV + "/get-token-users", requestData, { headers: { Authorization: `Bearer ${(localStorage.getItem('adminToken'))}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status == 200) {
        if (response.data.data.tokenUsersList.length > 0) {
          setTokenUsersList(response.data.data.tokenUsersList)
          setTotalCount(response.data.data.dailytTokensCount)
        }
      }
      else {
        setTokenUsersList([]);
        setTimeout(function () {
          $("#no-users").html("No users yet!");
        }, 200);
      }
    }).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
    dispatch(showLoaderAction(false));
  }

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs(), 'day');
  };

  const exportToExcel = async () => {
    const requestData = {
      encryptedClientId: selectedClient,
      pageNumber: 1,
      pageSize: totalCount,
      searchText: searchText,
      searchDate: date,
      status: ""
    };

    try {
      const response = await axios.post(REACT_APP_MY_ENV + "/get-token-users", requestData, { headers: { Authorization: `Bearer ${(localStorage.getItem('adminToken'))}`, "Content-Type": "application/json" } });

      if (response.data.status === 200 && response.data.data.tokenUsersList.length > 0) {
        const tokenUsersList = response.data.data.tokenUsersList;
        let serialNumber = 1;
        const headers = ['Sr No.', 'ClientName', 'Date Time', 'Name', 'Phone Number', 'Email', 'Token Number', 'Token Status'];
        const formattedData = [headers, ...tokenUsersList.map(user => [
          serialNumber++,
          props.outletName,
          user.date ? moment(user.date).format("DD-MM-YYYY hh:mm A") : "-",
          user.name,
          user.phoneNumber,
          user.emailAddress,
          user.tokenNumber,
          user.tokenStatus,
        ])];
        const dateObject = new Date(date);
        const formattedDateTime = `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(formattedData);
        XLSX.utils.book_append_sheet(wb, ws, `${props.outletName}_Tokens_${formattedDateTime}`);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(dataBlob);
        downloadLink.href = url;
        downloadLink.download = `${props.outletName}_Tokens_${formattedDateTime}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
      } else {
        console.error('No data found or error in response');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleClick = () => {
    exportToExcel();
  };

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div className="page-header d-print-none mt-20">
        <div className=" mb-2">
          <div className="row g-2 align-items-center">
            <div className="col">
              <h2 className="page-title">Client Tokens</h2>
            </div>
            <div class="col-auto ms-auto d-print-none">
              <div class="d-flex">
                <div class="input-icon mb-2">
                  <DatePicker
                    className="form-control"
                    format="YYYY-MM-DD"
                    value={date ? dayjs(date, 'YYYY-MM-DD') : null}
                    onChange={PreferredDateHandleChange}
                    disabledDate={disabledDate}
                    placeholder="Select Preferred Date"
                  />
                </div>
                <div class="input-icon mb-2">
                  <Link className="btn btn-primary ms-1 input-icon" onClick={handleClick}>
                    Export
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="card" >
          <div className="card-body border-bottom py-3">
            <div className="d-flex">
              <div className="text-muted">
                Show
                <div className="mx-2 d-inline-block">
                  <select
                    class="form-control"
                    onChange={handlePageSizeClick}
                    value={itemsPerPage}
                    selected={itemsPerPage}
                  >
                    <option value="10" >10</option>
                    <option value="25" >25</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                  </select>
                </div>
                entries
              </div>
              <div className="ms-auto text-muted">
                <div className="d-flex">
                  <input type="search" className="form-control d-inline-block w-9 me-2" value={searchText}
                    onKeyDown={handleKeyPress}
                    onChange={onSearchResult} aria-label="Search invoice" placeholder="Search here…" />
                  <button className="btn btn-primary " onClick={onSearchHandleClick}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table card-table table-vcenter text-nowrap datatable" >
              <thead>
                <tr>
                  <th className="w-1">
                    Sr. No.
                  </th>
                  <th>Name</th>
                  <th>Token Number</th>
                  <th>Time</th>
                  <th>Status</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tokenUsersList.length > 0 ? tokenUsersList && tokenUsersList.map((user, index) => {
                  return (
                    <tr >
                      <td>
                        <span className="text-muted">{++index}</span></td>
                      <td>{user.name}</td>
                      <td>{user.tokenNumber ? <span class="badge bg-cyan badge-pill">{user.tokenNumber}</span> : ""}</td>
                      <td>{user.date ? moment(user.date).format('hh:mm A') : "-"}</td>
                      <td>{user.tokenStatus === "InQueue" ? <span class="badge bg-azure">Ongoing</span> : user.tokenStatus === "Completed" ? <span class="badge bg-green">Completed</span> : user.tokenStatus === "Removed" ? <span class="badge bg-orange">No Show</span> : user.tokenStatus === "Cancelled" ? <span class="badge bg-red">Cancelled</span> : null}</td>
                      <td>{user.phoneNumber}</td>
                      <td>{user.emailAddress ? user.emailAddress : "-"}</td>
                    </tr>
                  );
                }) : <span className="no-record-message"><p>No records for selected date!</p></span>}
              </tbody>
            </table>
          </div>
          <div className="card-footer d-flex align-items-center">
            <p className="m-0 text-muted">
              Showing <span>{currentPage}</span> to <span>{itemsPerPage}</span> of <span>{totalCount}</span> entries
            </p>
            <ul className="pagination m-0 ms-auto">
              <li className="page-item ">
                <Link className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => handlePagePrevClick(currentPage - 1)} tabIndex="-1" aria-disabled="true">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M15 6l-6 6l6 6" />
                  </svg>
                  prev
                </Link>
              </li>
              {Array.from({ length: pageCount }, (_, i) => (
                <li key={i} className="page-item">
                  <Link
                    className={`page-link ${i + 1 === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageClick(i + 1)}
                  >
                    {i + 1}
                  </Link>
                </li>
              ))}
              <li className="page-item">
                <Link className={`page-link ${currentPage === pageCount ? 'disabled' : ''}`}
                  onClick={() => handlePageNextClick(+1)}
                >
                  next
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 6l6 6l-6 6" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default DailyTokenList;
