import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showLoaderAction } from "../../actions/index";
import SpinerLoader from "../SpinerLoader/spinerLoader";
import { useCatchHandler } from "../../utils/utilities"
import toast from "react-hot-toast";
import DownImage from "../../assests/img/3.svg";
import UpImage from "../../assests/img/4.svg";
import DefaultImage from "../../assests/img/outlet-default.png";
import moment from "moment/moment";
import Select from 'react-select';

const { REACT_APP_MY_ENV } = process.env;

const CustomerDetail = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  let token = localStorage.getItem("adminToken");
  const catchHandle = useCatchHandler();
  const currentURL = window.location.href;

  const [userId] = useState(location.state?.userId);
  const [client, setClient] = useState([]);
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [userDetail, setUserDetail] = useState("");
  const [walletDetails, setWalletDetails] = useState({});
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedClient, setSelectedClient] = useState("");
  const [transactionTotalCount, setTransactionTotalCount] = useState(0);
  const [tokenUsersHistory, setTokenUsersHistory] = useState([]);
  const [dailyTokensCount, setDailyTokensCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const pageCount = Math.max(1, Math.ceil(totalCount / itemsPerPage));
  const [transectionList, setTransectionList] = useState([]);

  useEffect(() => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split('/');
    const userDetailId = urlParts[urlParts.length - 1];
    GetUserDetail(userDetailId)
    fetchWalletDetails(userDetailId)
    TransactionList(userDetailId)
    getClient()
		getTokenUsersList(userDetailId)
  }, [])

  const GetUserDetail = (userDetailId) => {
    const requestData = {
      isAdmin: true,
      encryptedUserId: userId ? userId : userDetailId
    }
    dispatch(showLoaderAction(true));
    axios.post(REACT_APP_MY_ENV + "/get-user-detail/", requestData).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        setUserDetail(response.data.data.user)
      }
    }).catch(function (error) {
      catchHandle(error, "true", currentURL)
      //   showToast("Something went wrong, please contact Administrator!", "error")
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from outlet",
        BodyText: error.message
      }
    });
  };

  const fetchWalletDetails = async (userId) => {
    dispatch(showLoaderAction(true));
    const data = {
      UserId: userId,
    };
    const walletDetails = await axios.post(REACT_APP_MY_ENV + "/get-wallet", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (walletDetails.data.status === 200) {
      setWalletDetails(walletDetails.data.data);
    } else {
      toast.error(walletDetails.data.message);
    }
    dispatch(showLoaderAction(false));
  };

  const TransactionList = async (userId, pageNumber) => {
    let data = {
      UserId: userId,
      pageNumber: pageNumber ? pageNumber : currentPage,
      pageSize: itemsPerPage,
    };
    // dispatch(showLoaderAction(true));
    const res = await axios.post(REACT_APP_MY_ENV + "/transaction-list", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      if (res.data.status === 200) {
        setTransactionTotalCount(res.data.data.transactionTotalCount)
        setTransectionList(res.data.data.transactionList);
        dispatch(showLoaderAction(false));
      } else {
        dispatch(showLoaderAction(false));
         setTransectionList([]);
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));

      catchHandle(error, "true", currentURL)
      const data = {
          Subject: "Front end error from feedback-list",
          BodyText: error.message
      }
    });
  };

  const handlePageNextClick = () => {
    if (currentPage < pageCount) {
      setCurrentPage((prevPage) => prevPage + 1);
      TransactionList(userId, currentPage + 1);
    }
  };

  const handlePagePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      TransactionList(userId, currentPage - 1);
    }
  };
  const handlePageClick = (selected) => {
    setCurrentPage(selected);
    TransactionList(userId, selected);
  };

  const getClient = async (pageNumber) => {
    let data = {
      SearchText: "",
      pageNumber: pageNumber ? pageNumber : currentPage,
      pageSize: 10000
    }
    const response = await axios.post(REACT_APP_MY_ENV + "/client-list", data, { headers: { Authorization: `Bearer ${(localStorage.getItem('adminToken'))}`, "Content-Type": "application/json" } }).then(function (response) {
    if (response.data.status === 200) {
      dispatch(showLoaderAction(false));
      const userData = response.data.data.searchOutlet;
      let userOptions = [];
      userData.forEach((user) => {
        const userObj = {
          value: user.id,
          label: user.name
        };
        userOptions.push(userObj);
      });
      setClient(userOptions);
    } else {
      setClient([])
    }}).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
};
const getTokenUsersList = (userDetailId,ClientId, pageNumber) => {
    const requestData = {
        ClientId:ClientId ? ClientId : selectedClient,
        UserId: userDetailId,
        pageNumber: pageNumber ? pageNumber : currentPage,
        pageSize: itemsPerPage,
        }
    dispatch(showLoaderAction(true));
    axios.post(REACT_APP_MY_ENV + "/user-token-list", requestData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    }).then(function (response) {
        if (response.data.status === 200) {
            dispatch(showLoaderAction(false));
            setDailyTokensCount(response.data.data.totalToken)
            if (response.data.data.userTokenList.length > 0) {
                setTokenUsersHistory(response.data.data.userTokenList)
            }
        }
        else {
            dispatch(showLoaderAction(false));
            setTokenUsersHistory([]);
            setTimeout(function () {
            }, 200);
        }
    }).catch(function (error) {
        catchHandle(error, "false", currentURL)
        dispatch(showLoaderAction(false));
        const data = {
            Subject: "Front end error from get-token-users",
            BodyText: error.message
        }
    });
}

const getUpdateUserStatus = async (user) => {
	let data = {
		userId:(user.id).toString() ? (user.id).toString() : user.id,
		status: user.status === 1 ? "Deactive" : 0 ? "Active" : "Active",
	};
	const response = await axios.post(REACT_APP_MY_ENV + "/update-user-status", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
	if (response.data.status === 200) {
		toast.success(response.data.message);
		GetUserDetail(userId)
	} else {
		toast.error(response.data.message);
	}}).catch(function (error) {
		catchHandle(error)
		toast.error("Something went wrong, please contact Administrator!")
	});
};

const getUpdateWalletStatus = async (walletDetails) => {
  let data = {
    userId: userId,
    walletId:  walletDetails.walletId ? (walletDetails.walletId).toString() : walletDetails.walletId,
    isActive: walletDetails.isActive === 1 ? 0 : 1,
  };
  const response = await axios.post(REACT_APP_MY_ENV + "/update-wallet-status", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
    if (response.data.status === 200) {
    toast.success(response.data.message);
    fetchWalletDetails(userId)
    TransactionList(userId)
  } else {
    toast.error(response.data.message);
  }}).catch(function (error) {
    catchHandle(error)
    toast.error("Something went wrong, please contact Administrator!")
  });
};

const handleClientChange = (selectedOption) => {
    if (selectedOption) {
      const clientId = selectedOption.value.toString();
      setSelectedClient(clientId);
      getTokenUsersList(userId,clientId)
    }
    getTokenUsersList(userId)
  };

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div class="page-wrapper">
        <div class="">
          <div className="container-xl mt-4">
          <div class="row g-2 align-items-center">
            <div class="col">
              <h2 class="page-title">
              Customer Details
              </h2>
            </div>
            <div class="col-auto ms-auto d-print-none">
              <div class="d-flex justify-content-end ">
                <ol class="breadcrumb breadcrumb-arrows" aria-label="breadcrumbs">
                <li class="breadcrumb-item"><Link to={"/"} >Dashboard</Link></li>
                  <li class="breadcrumb-item"><Link to={"/customers"} >Customers</Link></li>
                  <li class="breadcrumb-item active" aria-current="page"><Link>Customer Detail</Link></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="page-header m-0 d-print-none">
          <div className="container-xl">
            <div className="row g-2 align-items-center">
            </div>
          </div>
        </div>
        <div className="page-header m-0 d-print-none">
          <div className="container-xl">
            <div class="card">
              <div class="card-body">
                <div class="datagrid">
                  <div class="datagrid-item">
                    <div class="datagrid-title">Name</div>
                    <div class="datagrid-content">{userDetail.name ? userDetail.name : "-"}</div>
                  </div>
                  <div class="datagrid-item">
                    <div class="datagrid-title">Phone Number</div>
                    <div class="datagrid-content">{userDetail.phoneNumber ? userDetail.phoneNumber : "-"}</div>
                  </div>
                  <div class="datagrid-item">
                    <div class="datagrid-title">Email Address</div>
                    <div class="datagrid-content">{userDetail.emailAddress ? userDetail.emailAddress : "-"}</div>
                  </div>
                  <div class="datagrid-item">
                    <div class="datagrid-title">Since</div>
                    <div class="datagrid-content">{userDetail.createdDateUtc ? moment(userDetail.createdDateUtc).format("DD-MM-YYYY hh:mm A") : "-"} </div>
                  </div>
                  <div class="datagrid-item">
                    <div class="datagrid-title">Birth Date</div>
                    <div class="datagrid-content">{userDetail.birthDate ? moment(userDetail.birthDate).format("DD-MM-YYYY hh:mm A") : "-"} </div>
                  </div>
                  <div class="datagrid-item">
                    <div class="datagrid-title">Anniversary Date</div>
                    <div class="datagrid-content">{userDetail.anniversaryDate ? moment(userDetail.anniversaryDate).format("DD-MM-YYYY hh:mm A") : "-"} </div>
                  </div>
                  <div class="datagrid-item">
                    <div class="datagrid-title">Status</div>
										<div class="datagrid-content">
											<label className="form-check form-check-single form-switch ps-0">
												<input className="form-check-input" type="checkbox"
													  onClick={() => getUpdateUserStatus(userDetail)} 
													checked={userDetail.status === 1}
												/>
											</label>
										</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="container-xl">
            <div class="row row-cards">
              <div class="col-lg-6 mt-0">
              <div className="container-xl mb-5">
                  {walletDetails.walletId != null &&
                    <>
                      <h2 className="page-title mt-4 mb-4">
                        Wallet Details
                      </h2>
                      <div class="card ">
                        <div class="table-responsive">
                          <table class="table table-vcenter table-bordered table-nowrap card-table">
                            <thead>
                              <tr>
                                <td class="text-center w-50">
                                  <div class="text-uppercase text-muted font-weight-medium">Balance</div>
                                  <div class="display-6 fw-bold my-2">₹{walletDetails.balance}</div>
                                </td>
                                <td class="text-center w-50">
                                  <div class="text-uppercase text-muted font-weight-medium wallet-margin">Wallet Status</div>
                                  <div class="display-6 fw-bold my-2 ">
                                    <div class="datagrid-content">
                                      <label className="form-check form-check-single form-switch ms-7">
                                        <input className="form-check-input" type="checkbox"
                                          onClick={() => getUpdateWalletStatus(walletDetails)}
                                          checked={walletDetails.isActive === 1}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </>
                  }
                  <div className="card mt-1">
                <div className="card-body p-0">
            <div class="card-header p-1">
                <h3 class="card-title">{walletDetails.walletId != null ? "Transaction History" : "Wallet not created yet!" }</h3>
              </div>
              </div>
              </div>
            <div className="card-container-transaction mt-1 ">
              {transectionList && transectionList.length > 0 ?
                transectionList && transectionList.map((data, index) => {
                  return (
                    <div className="col-lg-12">
                      <div className="card mt-1">
                        <div className="card-body card-transaction-padding">
                          <div class="list-group card-list-group">
                            <div class="list-group-item p-0">
                              <div class="row g-2 align-items-center">
                                <div class="col-auto">
                                  <img className="imgCard rounded" src={data.type === "CR" ? DownImage : data.type === "DR" ? UpImage : ""} alt="Górą ty" width="40" height="40" />
                                </div>
                                <div class="col">
                                  <span className="item-admin-title-wallet">{data.title}</span>
                                  <div class="text-muted">
                                    {data.description}
                                  </div>
                                </div>
                                <div class="col-auto text-muted">
                                  {data.createdAtUtc ? moment(data.createdAtUtc).format("DD-MM-YY hh:mm A") : ""}
                                  <div class="text-muted">
                                    <div class="token-item-text text-amount-color "> {data.type === "CR" ? <span className="text-success">+</span> : <span className="text-danger">-</span>} ₹{data.amount}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
                : "" }
                {transectionList.length > 0 ?
              <div className="card-footer d-flex align-items-center">
                <p className="m-0 text-muted">
                  Showing <span>{currentPage}</span> to <span>{itemsPerPage}</span> of <span>{totalCount}</span> entries
                </p>
                <ul className="pagination m-0 ms-auto">
                  <li className="page-item ">
                    <Link className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => handlePagePrevClick(currentPage - 1)} tabIndex="-1" aria-disabled="true">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 6l-6 6l6 6" />
                      </svg>
                      prev
                    </Link>
                  </li>
                  {Array.from({ length: pageCount }, (_, i) => (
                    <li key={i} className="page-item">
                      <Link
                        className={`page-link ${i + 1 === currentPage ? 'active' : ''}`}
                      onClick={() => handlePageClick(i + 1)}>
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                  <li className="page-item">
                    <Link className={`page-link ${currentPage === pageCount ? 'disabled' : ''}`}
                      onClick={() => handlePageNextClick(+1)}>
                      next
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 6l6 6l-6 6" />
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div> : ''}
            </div>
          </div>
              </div>
              <div class="col-lg-6 mt-0">
              <div className="container-xl mb-5">
            <h2 className="page-title mt-1 mb-2">
            Customer Tokens
            </h2>
            <div className="text-muted ">
                  <div className="d-inline-block w-100">
                    <Select className="selectTheme w-100"
                     placeholder="Select Client"
                      value={client.find((c) => c.value === selectedClient)}
                      onChange={(selectedOption) => handleClientChange(selectedOption)}
                      options={client}
                    />
                  </div>
                </div>
      
            <div className=" card-container-history mt-1 ">
              {tokenUsersHistory && tokenUsersHistory.length > 0 ?
                tokenUsersHistory && tokenUsersHistory.map((data, index) => {
                  return (
                    <div className="col-lg-12">
                      <div className="card mt-1">
                        <div className="card-body card-transaction-padding">
                          <div class="list-group card-list-group">
                            <div class="list-group-item p-0">
                              <div class="row g-2 align-items-center">
                                <div class="col-auto">
                                  <img className="imgCard rounded" src={data.logoFilePathURL ? data.logoFilePathURL : DefaultImage} alt="outlet img" onError={(error) => { error.target.src = DefaultImage }} width="40" height="40" />
                                </div>
                                <div class="col">
                                <span className="item-admin-title-wallet">{data.name} </span>
                                  <div class="text-muted">
                                   Token No.{data.tokenNumber}
                                  </div>
                                </div>
                                <div class="col-auto text-muted">
                                {data.tokenStatus === "InQueue" ? <span class="badge bg-azure">Ongoing</span> : data.tokenStatus === "Completed" ? <span class="badge bg-green">Completed</span> : data.tokenStatus === "Removed" ? <span class="badge bg-orange">No Show</span> : data.tokenStatus === "Cancelled" ? <span class="badge bg-red">Cancelled</span> : null}
                                  <div class="text-muted">
                                    <div class="token-item-text text-amount-color">{data.date ? moment(data.date).format("DD-MM-YY hh:mm A") : ""} 
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
                : "" }
               { tokenUsersHistory.length > 0 ?
              <div className="card-footer d-flex align-items-center">
                <p className="m-0 text-muted">
                  Showing <span>{currentPage}</span> to <span>{itemsPerPage}</span> of <span>{totalCount}</span> entries
                </p>
                <ul className="pagination m-0 ms-auto">
                  <li className="page-item ">
                    <Link className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => handlePagePrevClick(currentPage - 1)} tabIndex="-1" aria-disabled="true">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 6l-6 6l6 6" />
                      </svg>
                      prev
                    </Link>
                  </li>
                  {Array.from({ length: pageCount }, (_, i) => (
                    <li key={i} className="page-item">
                      <Link
                        className={`page-link ${i + 1 === currentPage ? 'active' : ''}`}
                      onClick={() => handlePageClick(i + 1)}>
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                  <li className="page-item">
                    <Link className={`page-link ${currentPage === pageCount ? 'disabled' : ''}`}
                      onClick={() => handlePageNextClick(+1)}>
                      next
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 6l6 6l-6 6" />
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div> :""}
            </div>
          </div>
              </div>
            </div>
          </div>
        </div>
    
      </div>
    </>
  )
}
export default CustomerDetail;
