import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast';
import { showLoaderAction } from "../../actions/index";
import { activeMenuAction } from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCatchHandler } from "../../utils/utilities"
const { REACT_APP_MY_ENV } = process.env;

const AddPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  let token = localStorage.getItem("adminToken");
  const catchHandle = useCatchHandler();
  const type = location.state?.type;

  const [planName, setPlanName] = useState("");
  const [price, setPrice] = useState("");
  const [monthlyDuration, setMonthlyDuration] = useState("");
  const [typeName, setTypeName] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [priceError, setPriceError] = useState({});
  const [planNameError, setPlanNameError] = useState({});
  const [monthlyDurationError, setMonthlyDurationError] = useState({});
  const [typeNameError, setTypeNameError] = useState({});

  useEffect(() => {
    dispatch(activeMenuAction('category'));
    if(type=== "update"){
      getPlanDetail()
    }else{
      setPlanName("")
      setPrice("")
    }
  }, [type]);

  const formValidation = () => {
    let priceError = {};
    let planNameError = {};
    let typeNameError = {};
    let monthlyDurationError = {};
    let isValid = true;

    if (!planName) {
      planNameError.empty = "Please enter plan name";
      isValid = false;
    }
    if (!price) {
      priceError.empty = "Please enter price";
      isValid = false;
    }

    if (!monthlyDuration) {
      monthlyDurationError.empty = "Please enter monthly duration";
      isValid = false;
    }
    if (!typeName) {
      typeNameError.empty = "Please select type";
      isValid = false;
    }

    setTypeNameError(typeNameError);
    setMonthlyDurationError(monthlyDurationError);
    setPriceError(priceError);
    setPlanNameError(planNameError);
    return isValid;
  };

  const getPlanDetail = async () => {
    let data = {
      planId: id
    }
    const response = await axios.post(REACT_APP_MY_ENV + "/view-plans", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
    if (response.data.status === 200) {
      setPlanName(response.data.data.name);
      setPrice(response.data.data.price);
      setTypeName(response.data.data.type);
      setMonthlyDuration(response.data.data.monthlyDuration);
      setIsActive(response.data.data.isActive);
    }
    else {
      toast.error(response.data.message);
    }}).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      if(type === "update"){
        let data = {
          PlanId:id,
          planName: planName,
          price : price,
          monthlyDuration : monthlyDuration,
          type: typeName,
          IsActive : isActive
        };
        const response = await axios.post(REACT_APP_MY_ENV + "/update-plans", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          navigate("/plans")
        } else {
          toast.error(response.data.message);
        }}).catch(function (error) {
          catchHandle(error)
          dispatch(showLoaderAction(false));
          toast.error("Something went wrong, please contact Administrator!")
        });
      }
      else{
        let data = {
          planName: planName,
          price : price,
          monthlyDuration : monthlyDuration,
          type: typeName,
          IsActive : isActive
        };
        const response = await axios.post(REACT_APP_MY_ENV + "/add-plans", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setPlanName("")
          setPrice("")
          setMonthlyDuration("")
          setTypeName("")
          navigate("/plans")
        } else {
          toast.error(response.data.message);
        }}).catch(function (error) {
          catchHandle(error)
          dispatch(showLoaderAction(false));
          toast.error("Something went wrong, please contact Administrator!")
        });
      }
     }
  };

  
  const getUpdatePlanStatus = async () => {
    let data = {
      PlanId: id,
      planName: planName,
      price: price,
      monthlyDuration: monthlyDuration,
      type: typeName,
      IsActive: isActive === 0 ? 1 : 0
    };
    const response = await axios.post(REACT_APP_MY_ENV + "/update-plans", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
    if (response.data.status === 200) {
      toast.success(response.data.message);
      getPlanDetail()
    } else {
      toast.error(response.data.message);
    }}).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
    }		

  return (
    <>
      <div className="page-body">
        <div className="container-xl mb-5">
        <div className="container-xl mb-3">
          <div class="row g-2 align-items-center">
            <div class="col">
            </div>
            <div class="col-auto ms-auto d-print-none">
              <div class="d-flex justify-content-end ">
                <ol class="breadcrumb breadcrumb-arrows" aria-label="breadcrumbs">
                <li class="breadcrumb-item"><Link to={"/"} >Dashboard</Link></li>
                <li class="breadcrumb-item"><Link to={"/plans"} >Plans</Link></li>
              <li class="breadcrumb-item active" aria-current="page"><Link> { type === "update" ? "Update" : "Add" } Plan</Link></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
          <div className="row row-cards">
            <div className="col-md-6">
              <form className="card" onSubmit={onSubmit}>
                <div className="card-header">
                  <h3 className="card-title"> { type === "update" ? "Update" : "Add" } Plan</h3>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label className="form-label required">Name</label>
                    <div>
                      <input type="text" className="form-control" value={planName} name="planName" onChange={(e) => setPlanName(e.target.value)}  maxLength={50} placeholder="Enter Plan Name" />
                      {Object.keys(planNameError).map((key) => {
                        return (
                          <p className="text-danger">
                            {planNameError[key]}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label required">Price</label>
                    <div>
                      <input type="text" className="form-control" value={price} name="price" onChange={(e) => setPrice(e.target.value)} maxLength={50} placeholder="Enter Price" />
                      {Object.keys(priceError).map((key) => {
                        return (
                          <p className="text-danger">
                            {priceError[key]}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                      <div className="col-6 me-2">
                      <label className="form-label required">Type</label>
                        <select
                          class="form-control"
                          name="typeName"
                          onChange={(e) => setTypeName(e.target.value)}
                          value={typeName}
                           selected={typeName}  >
                          <option value="" >Select Type</option>
                          <option value="Monthly" >Monthly</option>
                          <option value="Yearly" >Yearly</option>
                        </select>
                        {Object.keys(typeNameError).map((key) => {
                        return (
                          <p className="text-danger">
                            {typeNameError[key]}
                          </p>
                        );
                      })}
                      </div>
                      <div className="col-6">
                    <label className="form-label required">Monthly Duration</label>
                    <div>
                      <input type="text" className="form-control" value={monthlyDuration} name="monthlyDuration" onChange={(e) => setMonthlyDuration(e.target.value)} maxLength={50} placeholder="Enter Monthly Duration" />
                      {Object.keys(monthlyDurationError).map((key) => {
                        return (
                          <p className="text-danger">
                            {monthlyDurationError[key]}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                      </div>
                  <div className="mb-3">
                    <div className="">
                      <label className="row">
                        <span className="form-label requiredgit ">Status</span>
                          <label className="form-check ps-3 form-check-single form-switch">
                             <input
                              className="form-check-input"
                              type="checkbox"
                              checked={isActive === 1}
                              onChange={(e) => {
                                if (type === "update") {
                                  getUpdatePlanStatus()
                                }else{
                                setIsActive(e.target.checked ? 1 : 0);
                                }
                              }}
                            />
                          </label>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-end">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPlan;
