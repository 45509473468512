import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeMenuAction } from "../../actions/index";
import defaultOutlet from "../../assests/img/outlet-default.png";
import moment from "moment/moment";
import { showLoaderAction } from "../../actions/index";
import SpinerLoader from "../SpinerLoader/spinerLoader";
import $ from "jquery";
import * as XLSX from 'xlsx';
import { useCatchHandler } from "../../utils/utilities"
const { REACT_APP_MY_ENV } = process.env;

const Clients = () => {
  const dispatch = useDispatch();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [client, setClient] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const pageCount = Math.max(1, Math.ceil(totalCount / itemsPerPage));
  let token = localStorage.getItem("adminToken");
  const catchHandle = useCatchHandler();

  useEffect(() => {
    dispatch(activeMenuAction('client-list'));
    if (client.length <= 0)
      getClient("", 1, itemsPerPage);
  }, []);

  const getClient = async (Search_Text , pageNumber,itemPerPage) => {
    let data = {
      SearchText: Search_Text ? Search_Text :"",
      pageNumber: !Search_Text || pageNumber ? pageNumber : currentPage,
      pageSize: itemPerPage ? itemPerPage : itemsPerPage
    }
    dispatch(showLoaderAction(!searchText));
    const response = await axios.post(REACT_APP_MY_ENV + "/client-list", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
    if (response.data.status === 200) {
       dispatch(showLoaderAction(false));
      setClient(response.data.data.searchOutlet)
      setCurrentPage(pageNumber || currentPage)
      setTotalCount(response.data.data.totalClientsCount)
    } else {
      dispatch(showLoaderAction(false));
      setClient([]);
    }}).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
    dispatch(showLoaderAction(false));
  }

  const handlePageClick = (selected) => {
    setCurrentPage(selected);
    getClient("", selected)
  };

  const handlePageNextClick = () => {
    if (currentPage < pageCount) {
      setCurrentPage((prevPage) => prevPage + 1);
      getClient("", currentPage + 1);
    }
  };

  const handlePagePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      getClient("", currentPage - 1);
    }
  };

  const handlePageSizeClick = (e) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value);
    if (e.target.value >= 1) {
      getClient("", currentPage, value)
    } 
    else {
      setItemsPerPage();
      getClient("", 1, value)
    }
  };

  const onSearchResult = (e) => {
    setSearchText(e.target.value)
  }
  const onSearchHandleClick = (e) => {
    if (searchText) {
       getClient(searchText,1)
    } else {
      setSearchText("")
      getClient("",1)
    }
  }

  const clientStatusUpdate = async (clientDetail) => {
      const data = new FormData();
      data.append("IsStatusUpdate", true)
      data.append("EncryptedClientId", clientDetail.id)
      data.append("Status", clientDetail.status == 1 ? "Deactive" : "Active");
      dispatch(showLoaderAction(true));
      const res = await axios.post(REACT_APP_MY_ENV + "/client-update-profile", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" } }).then(function (res) {
        if (res.data.status === 200) {
          dispatch(showLoaderAction(false));
          toast.success(res.data.message);
          getClient('',currentPage)
        } else {
          dispatch(showLoaderAction(false));
          toast.error(res.data.message);
        }
      }).catch(function (error) {
        catchHandle(error,"false")
        dispatch(showLoaderAction(false));
        toast.error("Something went wrong, please contact Administrator!")
      }); 
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchHandleClick();
    }
  };

  const exportToExcel = async () => {
    const requestData = {
      SearchText: searchText,
      pageNumber: 1,
      pageSize: totalCount,
    };
  
    try {
      const response = await axios.post(REACT_APP_MY_ENV + "/client-list", requestData, { headers: { Authorization: `Bearer ${(localStorage.getItem('adminToken'))}`, "Content-Type": "application/json" } });
      
      if (response.data.status === 200 ) {
        const clientList = response.data.data.searchOutlet;
        let serialNumber = 1;
        const headers = ['Sr No.', 'Name', 'Phone Number', 'Email', 'Slug','QrUuId','OutletStatus' ,'Open Time','Close Time', 'Status', 'Ratings', 'Category Name',  'Status'];
        const formattedData = [headers, ...clientList.map(client => [
          serialNumber++, 
          client.name,
          client.phoneNumber,
          client.email,
          client.slug, 
          client.qrUuid, 
          client.outletStatus, 
          client.openTime,
          client.closeTime, 
          client.status, 
          client.ratings, 
          client.categoryName
        ])];
        const dateObject = new Date();
        const formattedDateTime = `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(formattedData);
        XLSX.utils.book_append_sheet(wb, ws, `Clients${formattedDateTime}`);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(dataBlob);
        downloadLink.href = url;
        downloadLink.download =  `Clients${formattedDateTime}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
      } else {
        console.error('No data found or error in response');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleClick = () => {
    exportToExcel();
  };

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div className="page-header d-print-none mt-25">
           <div className="page-header d-print-none mt-3">
          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title">
                  Clients
                </h2>
                <div className="text-muted mt-1">{currentPage}-{itemsPerPage} of {totalCount} Clients</div>
              </div>

              <div className="col-auto ms-auto d-print-none">
                <div className="d-flex">
                  <input type="search" className="form-control d-inline-block w-9 me-3"  value={searchText}      onKeyDown={handleKeyDown} onChange={onSearchResult} aria-label="Search invoice"placeholder="Search client…"/>
                  <button className="btn btn-primary " onClick={onSearchHandleClick}>
                    Search
                    </button>
                    <Link className="btn btn-primary ms-1 input-icon"
                     onClick={handleClick}
                     >
                    Export
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div className="page-body">
          <div className="container-xl">
            <div className="row row-cards">
            {client.length > 0 ? client && client.map((client, index) => {
                        return (
                          <div className="col-md-6 col-lg-3 ">
                              <div className="card">
                                <div className="card-body p-4 text-center d-flex flex-column align-items-center justify-content-center">
                                <Link to={"/client/" + client.id} state={{ type: "update", ClientId: client.id, ClientSlug: client.slug }} className="remove-underline-on-hover" >
                                  <span className="avatar avatar-xl mb-3 rounded"
                                  ><img src={client.logoFilePathURL ? client.logoFilePathURL : defaultOutlet} onError={(error) => { error.target.src = defaultOutlet }} /></span></Link>
                                  <h3 className="m-0 mb-1 text-dark"><Link to={"/client/" + client.id} state={{ type: "update", ClientId: client.id, ClientSlug: client.slug }} className="remove-underline-on-hover" >{client.name}</Link></h3>
                                  <div className="text-muted"><Link to={"/client/" + client.id} state={{ type: "update", ClientId: client.id, ClientSlug: client.slug }} className="remove-underline-on-hover text-secondary" >{client.categoryName}</Link></div>
                               <div className="text-muted"><Link to={"/client/" + client.id} state={{ type: "update", ClientId: client.id, ClientSlug: client.slug }} className="remove-underline-on-hover text-secondary" >{client.openTime ? moment(client.openTime, "hh:mm:ss").format("hh:mm A") : ""} - {client.closeTime ? moment(client.closeTime, "hh:mm:ss").format("hh:mm A") : ""}</Link></div>
                                  <div className="mt-2">
                                    <span className="">{client.outletStatus === 0 ? <span className="badge bg-gray">Closed</span> : <span className="badge bg-green-lt">Open</span>}</span>
                                  </div>
                                  <div className="me-5 mt-2"><label className="form-check form-check-single form-switch">
                                {/* <input className="form-check-input" type="checkbox"
                                checked={client.status === 1}
                                onClick={() => clientStatusUpdate(client)} 
                                 /> */}
                              </label></div>
                                </div>
                                <div className="d-flex">
                                  <a href={`mailto:${client.email}`} className="card-btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>
                                    Email</a>
                                  <a href={`tel:${client.phoneNumber}`} className="card-btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
                                    Call</a>
                                </div>
                              </div>
                          </div>
                 );
                }) :<span className="no-record-message"><p>No record found!</p></span>}
             
            </div>
            <div className="d-flex mt-4">
            <ul className="pagination  ms-auto">
                    <li className="page-item ">
                      <Link className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => handlePagePrevClick(currentPage - 1)} tabIndex="-1" aria-disabled="true">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M15 6l-6 6l6 6" />
                        </svg>
                        prev
                      </Link>
                    </li>
                    {Array.from({ length: pageCount }, (_, i) => (
                      <li key={i} className="page-item">
                        <Link
                          className={`page-link ${i + 1 === currentPage ? 'active' : ''}`}
                          onClick={() => handlePageClick(i + 1)}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    ))}
                    <li className="page-item">
                      <Link className={`page-link ${currentPage === pageCount ? 'disabled' : ''}`}
                        onClick={() => handlePageNextClick(+1)}
                      >
                        next
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 6l6 6l-6 6" />
                        </svg>
                      </Link>
                    </li>
                  </ul>
            </div>
          </div>
        </div>
      
    </>
  );
}

export default Clients;
