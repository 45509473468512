import React, { useState } from "react";
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from "axios";
import { useDispatch } from "react-redux";
import $ from 'jquery';
import { showLoaderAction } from "../../actions/index";
import { useCatchHandler } from "../../utils/utilities"
const { REACT_APP_MY_ENV } = process.env;

const ForgetPassword = () => {
  const catchHandle = useCatchHandler();
  const [adminEmail, setAdminEmail] = useState("");
  const [adminEmailError, setAdminEmailError] = useState({});
  const dispatch = useDispatch();

  const onSubmit = async () => {
    const isValid = formValidation();
    if (isValid) {
      const res = await axios.post(REACT_APP_MY_ENV + "/super-admin-forget-password/" + adminEmail).then(function (res) {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        $("form")[0].reset();
      } else {
        toast.error(res.data.message);
      }}).catch(function (error) {
        dispatch(showLoaderAction(false));
        toast.error("Something went wrong, please contact Administrator!")
      });
    }
  };

  const formValidation = () => {
    let clientIdError = {};
    let isValid = true;

    if (!adminEmail) {
      adminEmailError.adminIdEmpty =
        "Please enter email address";
      isValid = false;
    } else if (
      isNaN(adminEmail) &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(adminEmail)
    ) {
      adminEmailError.emailInvalid = "Please enter valid email address";
      isValid = false;
    } else if (
      !isNaN(adminEmail) &&
      !/^(\+\d{1,3}[- ]?)?\d{10}$/.test(adminEmail)
    ) {
      clientIdError.phoneInvalid = "Please enter valid phone number";
      isValid = false;
    }

    setAdminEmailError(clientIdError);
    return isValid;
  };

  return (
    <div class="page page-center">
      <div class="container container-tight py-4">
        <div class="card-body text-center py-4 p-sm-5">
         <img
            src="https://api.tokenlelo.com/logo/TokenLelo-VLogo-Black.png"
            height="120"
            className="mb-n2"
            alt=""
          /> 
        </div>
        <form class="card card-md" autocomplete="off"  novalidate>
          <div class="card-body">
            <h2 class="card-title text-center mb-4">Forgot password</h2>
            <p class="text-muted">Enter your email address and reset password link will be emailed to you. </p>
            <div class="mb-3">
              <label class="form-label">Email address</label>
              <input type="email" class="form-control" placeholder="Enter email" onChange={(e) => {
                setAdminEmail(e.target.value);
              }} />
              {adminEmailError &&
                Object.keys(adminEmailError).map((key) => {
                  return (
                    <div style={{ color: "red" }}>
                      {adminEmailError[key]}
                    </div>
                  );
                })}
            </div>
            <div class="form-footer">
              <Link to="" class="btn btn-primary w-100" onClick={onSubmit}>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>
                Send me rest password link
              </Link>
            </div>
          </div>
        </form>
        <div class="text-center text-muted mt-3">
          Forget it, <Link to="/sign-in">send me back</Link> to the sign in screen.
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword