import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeMenuAction } from "../../actions/index";
import { showLoaderAction } from "../../actions/index";
import SpinerLoader from "../SpinerLoader/spinerLoader";
import { useCatchHandler } from "../../utils/utilities"
import toast from "react-hot-toast";
import $ from "jquery";
const { REACT_APP_MY_ENV } = process.env;

const ClientQrDetails = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	let token = localStorage.getItem("adminToken");
	const catchHandle = useCatchHandler();

	const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
	const showLoader = showLoaderReducer.show;
	const [clientUpiList, setClientUpiList] = useState([]);
	const [clientUpiId, setClientUpiId] = useState("");
	const [ClientId] = useState(location.state?.ClientId);

	const [upiIdError, setUpiIdError] = useState({});
	const [updateUpiError, setUpiUpdateError] = useState({});

	useEffect(() => {
		dispatch(activeMenuAction('client-detail-list'));

		if (clientUpiList.length <= 0) {
			getClientUpiList();
		}
	}, []);

	const getClientUpiList = async () => {
		let data = {
			ClientId: ClientId ? (ClientId).toString() : "",
			IsAdmin: true
		}
		dispatch(showLoaderAction(true));
		const response = await axios.post(REACT_APP_MY_ENV + "/get-client-upi-id", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
			if (response.data.status === 200) {
				dispatch(showLoaderAction(false));
				setClientUpiList(response.data.data.clientUpiList)

			} else {
				dispatch(showLoaderAction(false));
				setClientUpiList([]);
			}
		}).catch(function (error) {
			catchHandle(error)
			dispatch(showLoaderAction(false));
			toast.error("Something went wrong, please contact Administrator!")
		});
		dispatch(showLoaderAction(false));
	}

	const UpiIdValidation = () => {

		let upiIdError = {};
		let isValid = true;
		const upiIdPattern = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/;
		if (!clientUpiId) {
			upiIdError.empty = "Please enter upi id";
			isValid = false;
		}
		else if (!upiIdPattern.test(clientUpiId)) {
			upiIdError.format = "Please enter a valid UPI ID format";
			isValid = false;
		}
		setUpiIdError(upiIdError);
		return isValid;
	};

	const UpiUpdateIdValidation = (data) => {
		let upiIdUpdateError = {};
		let isValid = true;
		const upiIdPattern = /^[0-9A-Za-z.-]+@[A-Za-z]{2,64}$/;

		if (!data.upiId) {
			upiIdUpdateError.empty = "Please enter UPI ID";
			isValid = false;
		} else if (!upiIdPattern.test(data.upiId)) {
			upiIdUpdateError.format = "Please enter a valid UPI ID format";
			isValid = false;
		}

		setUpiUpdateError(upiIdUpdateError);
		return isValid;
	};

	const removUpiId = async (value) => {
		const data = {
			Id: (value.id).toString()
		}
		const removUpiIdDetail = await axios.delete(REACT_APP_MY_ENV + "/delete-client-upi-id",
			{ data, headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
		).then(function (removUpiId) {
			if (removUpiId.data.status === 200) {
				$("#openUpdateModal").trigger("click");
				getClientUpiList()
				toast.success(removUpiId.data.message)
			}
			return null;
		}).catch(function (error) {
			dispatch(showLoaderAction(false));
			toast.error("Something went wrong, please contact Administrator!")
			catchHandle(error, "true")
		});
		return null;
	}

	const AddSubscription = async (value) => {
		const isValid = UpiIdValidation();
		if (isValid) {
			const upiName = getAddUpiName(value);
			let data = {
				clientId: (ClientId).toString(),
				upiId: value,
				name: upiName

			};
			const response = await axios.post(REACT_APP_MY_ENV + "/create-client-upi", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
				if (response.data.status === 200) {
					toast.success(response.data.message);
					$("#openAcceptModal").trigger("click");
					setClientUpiId("")
					getClientUpiList()
				} else {
					toast.error(response.data.message);
				}
			}).catch(function (error) {
				catchHandle(error)
				toast.error("Something went wrong, please contact Administrator!")
			});
		}
	};

	const getUpdateClientUpi =  (clientUpi) => {
		const upiName = getUpdateUpiName(clientUpi);
		let data = {
			Id: (clientUpi.id).toString() ? (clientUpi.id).toString() : '',
			upiId: clientUpi.upiId ? clientUpi.upiId : "",
			name: upiName
		};
		dispatch(showLoaderAction(true));
		 axios.post(REACT_APP_MY_ENV + "/update-client-upi-id", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
			if (response.data.status === 200) {
				$(`#openUpdateModal_${clientUpi.id}`).trigger("click")
				dispatch(showLoaderAction(false));
				toast.success(response.data.message);
				getClientUpiList()
			} else {
				dispatch(showLoaderAction(false));
				toast.error(response.data.message);
			}
		}).catch(function (error) {
			dispatch(showLoaderAction(false));
			toast.error("Something went wrong, please contact Administrator!")
			catchHandle(error)
			dispatch(showLoaderAction(false));
			toast.error("Something went wrong, please contact Administrator!")
		});
	}

	const HandleAddUpiIdChange = (e) => {
		const value = e.target.value;
		if (value) {
			setClientUpiId(value)
		} else {
			setClientUpiId("")
		}
	}

	function getUpdateUpiName(data) {
		const atIndex = data.upiId.indexOf('@');
		if (atIndex !== -1) {
			return data.upiId.substring(atIndex + 1);
		} else {
			return null;
		}
	}

	function getAddUpiName(upiId) {
		const atIndex = upiId.indexOf('@');
		if (atIndex !== -1) {
			return upiId.substring(atIndex + 1);
		} else {
			return null;
		}
	}

	const hideInputBoxOnClick = async (data) => {
		const isValidUpiId = UpiUpdateIdValidation(data);

		if (isValidUpiId) {
				const updatedClientUpiList = clientUpiList.map((upi) => {
					if (upi.id === data.id) {
						return { ...upi, upiId: data.upiId };
					}
					return upi;
				});
				await getUpdateClientUpi(data);
				setUpiUpdateError("");
		}
	};


	const HandleUpdateUpiIdChange = (e, index) => {
		const value = e.target.value;
	
		setClientUpiList((prevList) => {
			const updatedList = prevList.map((item, i) => {
				if (i === index) {
					return {
						...item,
						upiId: value,
					};
				}
				return item;
			});
	
			return updatedList;
		});
	};

	
	const onSubmit = async (e) => {
		e.preventDefault();
		const isValidUpiId = UpiIdValidation();
		if (isValidUpiId) {
			await AddSubscription(clientUpiId);
		} else {
		}
	};

	const clearUPiForm = e => {
		setClientUpiId("");
		setUpiUpdateError({})
		setUpiIdError({})
	  }

	return (
		<>
			{showLoader && (<SpinerLoader />)}
			<div className="col mt-4" >
				<h2 className="page-title ">
					QRs
				</h2>
			</div>
			<div className="card mb-5 mt-5 ">
				<div className="row g-0" >
					<div className="col d-flex">
						<div className="card-body" >
							<div className="row " >
								<div className="d-flex align-self-end justify-content-end mb-3">
								<div className="col-md-6 text-start">
								<h3 className="" data-bs-toggle="modal"
								 data-bs-target="#modal-upi-add" 
								 id="openAcceptModal">UUID</h3>
								<p className="card-subtitle h2">{props.formData.qrUuid}</p>
									</div>
									<div className="col-md-6 text-end">
										<Link to="" className="btn btn-md btn-primary" data-bs-toggle="modal" data-bs-target="#modal-upi-add">
											Add UPI ID
										</Link>
									</div>
								</div>
								{clientUpiList.length > 0 ? (
									<div className="row">
										{clientUpiList.map((clientUpi, index) => (
											<div key={index} className="col-auto me-2">
												<div className="modal modal-blur fade" 
												id={`modal_upi_update_${clientUpi.id}`}
												tabindex="-1" role="dialog" aria-hidden="true" onSubmit={hideInputBoxOnClick}>
													<div className="modal-dialog modal-dialog-centered" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Update UPI ID</h5>
																<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
															</div>
															<div className="modal-body">
																<div className="row align-items-end">
																	<div className="mb-3 col-sm-4 col-md-6">
																		
																		<label className="form-label required">UPI ID</label>
																		<input
																			type="text"
																			className="form-control "
																			value={clientUpi.upiId ? clientUpi.upiId : ""}
																			onChange={(e) => HandleUpdateUpiIdChange(e, index)}
																			placeholder="Enter UPI ID"
																		/>
																		{Object.keys(updateUpiError).map((key) => {
																			return (
																				<p className="text-danger">
																					{updateUpiError[key]}
																				</p>
																			);
																		})}
																	</div>
																</div>
															</div>
															<div className="modal-footer">
																<button type="button" className="btn me-auto" data-bs-dismiss="modal">Cancel</button>
																<button type="button" className="btn btn-primary" onClick={() => hideInputBoxOnClick(clientUpi)}>Update</button>
															</div>
														</div>
													</div>
												</div>
												<h3 className="card-title">UPI ID {index + 1}</h3>
													<>
														<div className="d-flex me-5 mb-5" 	data-bs-toggle="modal" 
																	data-bs-target={`#modal_upi_update_${clientUpi.id}`}
																	id={`openUpdateModal_${clientUpi.id}`}
																	>
															<div className="col-auto" key={clientUpi.id} >
																<h3 className="text-align-center text-center mt-2"  >
																	{clientUpi.upiId ? clientUpi.upiId : "-"}
																</h3>
															</div>
															<div className="col-auto me-4">
																<button className="btn btn-md-save-link-icon ms-1"
																	data-bs-toggle="modal" 
																	data-bs-target={`#modal_upi_update_${clientUpi.id}`}
																>
																	<svg
																		className="icon-svg-delete icon"
																		xmlns="http://www.w3.org/2000/svg"
																		width="24"
																		height="24"
																		viewBox="0 0 24 24"
																		strokeWidth="2"
																		stroke="currentColor"
																		fill="none"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	>
																		<path stroke="none" d="M0 0h24v24H0z" fill="none" />
																		<path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
																		<path d="M13.5 6.5l4 4" />
																	</svg>
																</button>
																<Link className="btn btn-md-save-link-icon" data-bs-toggle="modal" data-bs-target="#modal-simple">
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		className="icon-svg-delete icon"
																		width="24"
																		height="24"
																		viewBox="0 0 24 24"
																		strokeWidth="2"
																		stroke="currentColor"
																		fill="none"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	>
																		<path stroke="none" d="M0 0h24v24H0z" fill="none" />
																		<path d="M4 7h16" />
																		<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
																		<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
																		<path d="M10 12l4 4m0 -4l-4 4" />
																	</svg>
																</Link>
															</div>
														</div>
														<div class="modal modal-blur fade" id="modal-simple" role="dialog" aria-hidden="true" >
															<div class="modal-dialog modal-dialog-centered" role="document">
																<div class="modal-content">
																	<div class="modal-header">
																		<h5 class="modal-title">Confirmation</h5>
																		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
																	</div>
																	<div class="modal-body">
																		Are you sure you want to remove this Upi Id
																	</div>
																	<div class="modal-footer">
																		<button type="button" class="btn me-auto" data-bs-dismiss="modal">Cancel</button>
																		<button type="button" class="btn btn-primary" onClick={() => removUpiId(clientUpi)} data-bs-dismiss="modal">Yes</button>
																	</div>
																</div>
															</div>
														</div>
													</>
											</div>
										))}
									</div>
								) : <>
								</>}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal modal-blur fade" id="modal-upi-add" tabindex="-1" role="dialog" aria-hidden="true" onSubmit={onSubmit}>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add UPI ID</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearUPiForm}></button>
						</div>
						<div className="modal-body">
							<div className="row align-items-end">
								<div className="mb-3 col-sm-4 col-md-6">
									<label className="form-label required">UPI ID</label>
									<input
										type="text"
										className="form-control"
										value={clientUpiId}
										onChange={HandleAddUpiIdChange}
										placeholder="Enter UPI ID"
									/>
									{Object.keys(upiIdError).map((key) => {
										return (
											<p className="text-danger">
												{upiIdError[key]}
											</p>
										);
									})}
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn me-auto" data-bs-dismiss="modal" onClick={clearUPiForm}>Cancel</button>
							<button type="button" className="btn btn-primary" onClick={onSubmit}>Add</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ClientQrDetails