import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { showLoaderAction } from "../../actions/index";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useCatchHandler } from "../../utils/utilities"
import $ from "jquery";

const { REACT_APP_MY_ENV } = process.env;

const ChangeAdminPassWord = () => {
	let token = localStorage.getItem("adminToken");
	const catchHandle = useCatchHandler();
	const [email, setEmail] = useState("");
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfimrPassword] = useState("");

	const [oldPasswordError, setOldPasswordError] = useState({});
	const [newPasswordError, setNewPasswordError] = useState({});
	const [ConfirmPasswordError, setConfirmPasswordError] = useState({});

	let AdminId = localStorage.getItem('encryptedAdminId')
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const formValidation = () => {
		let oldPasswordError = {};
		let newPasswordError = {};
		let ConfirmPasswordError = {};
		let isValid = true;

		if (!oldPassword) {
			oldPasswordError.passwordEmpty =
				"Please enter old password";
			isValid = false;
		}

		if (!newPassword) {
			newPasswordError.passwordEmpty = "Please enter new password";
			isValid = false;
		} else if (newPassword.length > 10) {
			newPasswordError.passwordLength = `Password not greater then ${10} characters`;
			isValid = false;
		}

		if (!confirmPassword) {
			ConfirmPasswordError.passwordEmpty = "Please enter confirm password";
			isValid = false;
		} else if (newPassword !== confirmPassword) {
			ConfirmPasswordError.passwordMatch = "Passwords do not match";
			isValid = false;
		}

		setNewPasswordError(newPasswordError);
		setConfirmPasswordError(ConfirmPasswordError);
		setOldPasswordError(oldPasswordError);
		return isValid;
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const isValid = formValidation();
		if (isValid) {
			const data = {
				adminId: AdminId,
				oldPassword: oldPassword,
				newPassword: newPassword,
			};
			const res = await axios.post(REACT_APP_MY_ENV + "/change-admin-password", data,{ headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (res) {
				if (res.data.status === 200) {

					toast.success(res.data.message)
					navigate("/");
				} else {
					toast.error(res.data.message);
				}
			}).catch(function (error) {
				dispatch(showLoaderAction(false));
				toast.error("Something went wrong, please contact Administrator!")
			});
		}
	};

	function showHideConfirmPassword() {
		var passInput = $('.confirm-pass').parent().find('input');
		var inputType = passInput.attr('type');
		if (inputType == 'password') {
			$('.confirm-pass').toggleClass('active');
			passInput.attr('type', 'text');
		} else if (inputType == 'text') {
			$('.confirm-pass').removeClass('active');
			passInput.attr('type', 'password');
		}
	}

	function showHideNewPassword() {
		var passInput = $('.new-pass').parent().find('input');
		var inputType = passInput.attr('type');
		if (inputType == 'password') {
			$('.new-pass').toggleClass('active');
			passInput.attr('type', 'text');
		} else if (inputType == 'text') {
			$('.new-pass').removeClass('active');
			passInput.attr('type', 'password');
		}
	}

	function showHideOldPassword() {
		var passInput = $('.old-pass').parent().find('input');
		var inputType = passInput.attr('type');
		if (inputType == 'password') {
			$('.old-pass').toggleClass('active');
			passInput.attr('type', 'text');
		} else if (inputType == 'text') {
			$('.old-pass').removeClass('active');
			passInput.attr('type', 'password');
		}
	}

	return (
		<div class="page page-center">
			<div class="container container-normal py-4">
				<div class="row align-items-center g-4">
					<div class="col-lg">
						<div class="container-tight">
							<div class="text-center mb-4">
								<a href="" class="navbar-brand navbar-brand-autodark">  <img
									src="https://api.tokenlelo.com/logo/TokenLelo-VLogo-Black.png"
									height="120"
									className="mb-n2"
									alt=""
								/> </a>
							</div>
							<div class="card card-md">
								<div class="card-body">
									<h2 class="h2 text-center mb-4">Change Account Password</h2>
									<form
										onSubmit={onSubmit}
									>
										<div class="mb-2">
											<label class="form-label">
												Old Password
												<span class="form-label-description">
												</span>
											</label>
											<div class="input-group input-group-flat">
												<input type="password" class="form-control" placeholder="Old password" autocomplete="off" onChange={(e) => {
													setOldPassword(e.target.value);
												}} />
												<span class="input-group-text old-pass">
													<Link to="" class="link-secondary" id="show-pass" title="Show password" onClick={showHideOldPassword} data-bs-toggle="tooltip">
														<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
													</Link>
												</span>
											</div>
											{oldPasswordError &&
												Object.keys(oldPasswordError).map((key) => {
													return (
														<div style={{ color: "red" }}>
															{oldPasswordError[key]}
														</div>
													);
												})}
										</div>
										<div class="mb-2">
											<label class="form-label">
												New Password
												<span class="form-label-description">
												</span>
											</label>
											<div class="input-group input-group-flat">
												<input type="password" class="form-control" placeholder="New Password" autocomplete="off" onChange={(e) => {
													setNewPassword(e.target.value);
												}} />
												<span class="input-group-text new-pass">
													<Link to="" class="link-secondary" id="show-pass" title="Show password" onClick={showHideNewPassword} data-bs-toggle="tooltip">
														<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
													</Link>
												</span>
											</div>
											{newPasswordError &&
												Object.keys(newPasswordError).map((key) => {
													return (
														<div style={{ color: "red" }}>
															{newPasswordError[key]}
														</div>
													);
												})}
										</div>
										<div class="mb-2">
											<label class="form-label">
												Confirm Password
												<span class="form-label-description">
												</span>
											</label>
											<div class="input-group input-group-flat">
												<input type="password" class="form-control" placeholder="Confirm Password" autocomplete="off" onChange={(e) => {
													setConfimrPassword(e.target.value);
												}} />
												<span class="input-group-text confirm-pass">
													<Link to="" class="link-secondary" id="show-pass" title="Show password" onClick={showHideConfirmPassword} data-bs-toggle="tooltip">
														<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
													</Link>
												</span>
											</div>
											{ConfirmPasswordError &&
												Object.keys(ConfirmPasswordError).map((key) => {
													return (
														<div style={{ color: "red" }}>
															{ConfirmPasswordError[key]}
														</div>
													);
												})}
										</div>
										<div class="form-footer">
											<button type="submit" class="btn btn-primary w-100">Change Password</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ChangeAdminPassWord