import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast';
import { showLoaderAction } from "../../actions/index";
import { activeMenuAction } from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCatchHandler } from "../../utils/utilities"
const { REACT_APP_MY_ENV } = process.env;

const AddCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  let token = localStorage.getItem("adminToken");
  const catchHandle = useCatchHandler();
  const type = location.state?.type;

  const [categoryName, setCategoryName] = useState("");
  const [topOrder, setTopOrder] = useState("");
  const [isActive, setIsActive] = useState(0);
  const [topOrderError, setTopOrderError] = useState("");
  const [categoryNameError, setCategoryNameError] = useState("");
  
  useEffect(() => {
    dispatch(activeMenuAction('category'));
    if(type=== "update"){
      getCategoryDetail()
    }else{
      setCategoryName("")
      setTopOrder("")
    }
  }, [type]);

  const formValidation = () => {
    let topOrderError = {};
    let categoryNameError = {};
    let isValid = true;

    if (!categoryName) {
      categoryNameError.empty = "Please enter category name";
      isValid = false;
    }
    if (!topOrder) {
      topOrderError.empty = "Please enter top order";
      isValid = false;
    }

    setTopOrderError(topOrderError);
    setCategoryNameError(categoryNameError);
    return isValid;
  };

  const getCategoryDetail = async () => {
    let data = {
      categoryId: id
    }
    const response = await axios.post(REACT_APP_MY_ENV + "/view-categories", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
    if (response.data.status === 200) {
      setCategoryName(response.data.data.name);
      setTopOrder(response.data.data.topOrder);
      setIsActive(response.data.data.isActive);
    }
    else {
      toast.error(response.data.message);
    }}).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      if(type === "update"){
        let data = {
          categoryId:id,
          categoryName: categoryName,
          TopOrder : topOrder,
          IsActive : isActive
        };
        const response = await axios.post(REACT_APP_MY_ENV + "/update-categories", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          navigate("/categories")
        } else {
          toast.error(response.data.message);
        }}).catch(function (error) {
          catchHandle(error)
          dispatch(showLoaderAction(false));
          toast.error("Something went wrong, please contact Administrator!")
        });
      }
      else{
        let data = {
          CategoryName: categoryName,
          TopOrder : topOrder,
          IsActive : isActive
        };
        const response = await axios.post(REACT_APP_MY_ENV + "/add-categories", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setCategoryName("")
          setTopOrder("")
          navigate("/categories")
        } else {
          toast.error(response.data.message);
        }}).catch(function (error) {
          catchHandle(error)
          dispatch(showLoaderAction(false));
          toast.error("Something went wrong, please contact Administrator!")
        });
      }
    }
  };

  const getUpdateCategory = async (categories) => {
    let data = {
      categoryId: id,
      categoryName: categoryName,
      TopOrder: topOrder,
      IsActive: isActive === 0 ? 1 : 0
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/update-categories", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        toast.success(response.data.message);
        getCategoryDetail()
      } else {
        dispatch(showLoaderAction(false));
        toast.error(response.data.message);
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
  }

  return (
    <>
      <div className="page-body">
        <div className="container-xl mb-5">
        <div className="container-xl mb-3">
          <div class="row g-2 align-items-center">
            <div class="col">
            </div>
            <div class="col-auto ms-auto d-print-none">
              <div class="d-flex justify-content-end ">
                <ol class="breadcrumb breadcrumb-arrows" aria-label="breadcrumbs">
                <li class="breadcrumb-item"><Link to={"/"} >Dashboard</Link></li>
                <li class="breadcrumb-item"><Link to={"/categories"} >Categories</Link></li>
              <li class="breadcrumb-item active" aria-current="page"><Link> { type === "update" ? "Update" : "Add" } Category  </Link></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
          <div className="row row-cards">
            <div className="col-md-6">
              <form className="card" onSubmit={onSubmit}>
                <div className="card-header">
                  <h3 className="card-title"> { type === "update" ? "Update" : "Add" } Category</h3>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label className="form-label required">Name</label>
                    <div>
                      <input type="text" className="form-control" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} maxLength={50} placeholder="Enter Category Name" />
                      {Object.keys(categoryNameError).map((key) => {
                        return (
                          <p className="text-danger">
                            {categoryNameError[key]}
                          </p>
                        );
                      })}
                      {/* <small className="form-hint">We'll never share your email with anyone else.</small> */}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label required">Top Order</label>
                    <div>
                      <input type="number" className="form-control" value={topOrder} onChange={(e) => setTopOrder(e.target.value)} min="1" placeholder="Enter Top Order" />
                      {Object.keys(topOrderError).map((key) => {
                        return (
                          <p className="text-danger">
                            {topOrderError[key]}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="divide-y">
                      <label className="row">
                        <span className="col form-label requiredgit ">Status</span>
                        <span className="col-auto">
                          <label className="form-check form-check-single form-switch">
                             <input
                              className="form-check-input"
                              type="checkbox"
                              checked={isActive === 1}
                              onChange={(e) => {
                                if (type === "update") {
                                  getUpdateCategory()
                                } else {
                                  setIsActive(e.target.checked ? 1 : 0);
                                }
                              }}
                            />
                          </label>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-end">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCategory;
