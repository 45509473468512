import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../logo.png";
import $ from "jquery";
import {useSelector } from "react-redux";

const Sidebar = () => {
   const activeMenuReducer = useSelector((state) => state.rootReducer.activeMenu);
   var activeMenu = activeMenuReducer.ActiveMenu;
  useEffect(() => {
    $(".nav-item").click(function () {
      $(".nav-item").removeClass("active");
      $(this).addClass("active");
    });
  }, []);
  return (
    <>
      <aside className="navbar navbar-vertical navbar-expand-lg navbar-transparent">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebar-menu" aria-controls="sidebar-menu" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark">
            <Link to={"/"}>
              <img src={Logo} width="110" height="32" alt="Tabler" className="navbar-brand-image" />
            </Link>
          </h1>
          <div className="collapse navbar-collapse" id="sidebar-menu">
            <ul className="navbar-nav pt-lg-3">
              <li className={`nav-item ${activeMenu === "dashboard" ? "active" : ""}`}>
                <Link className="nav-link" to={"/"}>
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                      <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                      <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                    </svg>
                  </span>
                  <span className="nav-link-title">Dashboard</span>
                </Link>
              </li>
              <li className={`nav-item  dropdown ${activeMenu === "category" || "category-list" ? "active" : ""}`}>
                <a className="nav-link " href="#navbar-base" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3l3 6l6 3l-6 3l-3 6l-3 -6l-6 -3l6 -3z" /></svg>
                  </span>
                  <span className="nav-link-title">Categories</span>
                </a>
                <div className="dropdown-menu">
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                    <Link  className={`dropdown-item ${activeMenu === "category-list" ? "active" : ""}`} to="/categories">
                        List
                      </Link>
                      <Link  className={`dropdown-item ${activeMenu === "category" ? "active" : ""}`} to="/add-category">
                        Add
                      </Link>
                      {/* <div className="dropend">
                    <a className="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="false">
                      Cards
                      <span className="badge badge-sm bg-green-lt text-uppercase ms-auto">New</span>
                    </a>
                    <div className="dropdown-menu">
                      <a href="./cards.html" className="dropdown-item">
                        Sample cards
                      </a>
                      <a href="./card-actions.html" className="dropdown-item">
                        Card actions
                        <span className="badge badge-sm bg-green-lt text-uppercase ms-auto">New</span>
                      </a>
                      <a href="./cards-masonry.html" className="dropdown-item">
                        Cards Masonry
                      </a>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </li>
              <li className={`nav-item  dropdown ${activeMenu === "category" || "client-list" ? "active" : ""}`}>
                <Link className="nav-link" to="/clients">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 21l18 0" /><path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" /><path d="M5 21l0 -10.15" /><path d="M19 21l0 -10.15" /><path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" /></svg>
                  </span>
                  <span className="nav-link-title">Clients</span>
                </Link>
              </li>
              <li className={`nav-item  dropdown ${activeMenu === "daily-tokens" ? "active" : ""}`}>
                <Link className="nav-link" to="/daily-tokens">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 5l0 2" /><path d="M15 11l0 2" /><path d="M15 17l0 2" /><path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2" /></svg>
                  </span>
                  <span className="nav-link-title">Daily Tokens</span>
                </Link>
              </li>
              <li className={`nav-item  dropdown ${activeMenu === "user-list" ? "active" : ""}`}>
              <Link  className="nav-link" to="/customers">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
                  </span>
                  <span className="nav-link-title">Customers</span>
                </Link>
              </li>
              <li className={`nav-item  dropdown ${activeMenu === "outlet-requests" ? "active" : ""}`}>
              <Link  className="nav-link" to="/outlet-requests">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg> */}
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 12l4 4l4 -4" /><path d="M12 8v8" /><path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /></svg>
                  </span>
                  <span className="nav-link-title">Outlet Requests</span>
                </Link>
              </li>
              <li className={`nav-item  dropdown ${activeMenu === "reviews" ? "active" : ""}`}>
              <Link  className="nav-link" to="/reviews">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 2l3 5h6l-3 5l3 5h-6l-3 5l-3 -5h-6l3 -5l-3 -5h6z" /></svg>    {/* <svg height="24" viewBox="0 0 24 24" width="24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="m21.645 8.769-5.817-.85-2.928-5.866a1.042 1.042 0 0 0 -1.79 0l-2.938 5.866-5.817.85a1 1 0 0 0 -.492 1.76l4.805 3.963-1.515 6.79a1 1 0 0 0 1.56 1.03l5.287-3.803 5.287 3.8a1 1 0 0 0 1.56-1.03l-1.515-6.79 4.805-3.963a1 1 0 0 0 -.492-1.76zm-6.059 4.571a1 1 0 0 0 -.339.989l1.076 4.826-3.739-2.689a1 1 0 0 0 -1.168 0l-3.739 2.689 1.076-4.826a1 1 0 0 0 -.339-.989l-3.538-2.918 4.1-.6a1 1 0 0 0 .75-.542l2.274-4.545 2.272 4.545a1 1 0 0 0 .75.542l4.1.6z"></path></svg> */}
                  </span>
                  <span className="nav-link-title">Reviews</span>
                </Link>
              </li>
              <li className={`nav-item  dropdown ${activeMenu === "plans" ? "active" : ""}`}>
              <Link  className="nav-link" to="/plans">
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 5h12l3 5l-8.5 9.5a.7 .7 0 0 1 -1 0l-8.5 -9.5l3 -5" /><path d="M10 12l-2 -2.2l.6 -1" /></svg>
                  </span>
                  <span className="nav-link-title">Plans</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
