import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from "axios";
import $ from 'jquery';
import { useDispatch } from "react-redux";
import { showLoaderAction } from "../../actions/index";
import { useNavigate, useParams } from "react-router-dom";
const { REACT_APP_MY_ENV } = process.env;

const ResetPassword = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [validate, setValidate] = useState("");
    const [adminId, setAdminId] = useState("");

    const [passwordError, setPasswordError] = useState({});
    const [confPasswordError, setConfPasswordError] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        validateClient(id);
    }, []);

    async function validateClient(id) {
        const res = await axios.get(
            REACT_APP_MY_ENV + "/validate-admin/" + id).then(function (res) {
        if (res.data.status === 200) {
            setValidate(true);
            setAdminId(id);
        }}).catch(function (error) {
            dispatch(showLoaderAction(false));
            toast.error("Something went wrong, please contact Administrator!")
          });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const isValid = formValidation();
        const data = {
            "email": id,
            "newPassword": password,
        }
        if (isValid) {
            const res = await axios.post(
                REACT_APP_MY_ENV + "/super-admin-reset-password/", data).then(function (response) {
            if (response.data.status === 200) {
                toast.success(response.data.message);
                navigate('/sign-in');
            } else {
                toast.error(response.data.message);
            }
        })
        .catch(function (error) {
                dispatch(showLoaderAction(false));
                toast.error("Something went wrong, please contact Administrator!")
              });
        }
    };

    function showHidePwd() {
        var passInput = $('.show-pass').parent().find('input');
        var inputType = passInput.attr('type');
        if (inputType == 'password') {
            $('.show-pass').toggleClass('active');
            passInput.attr('type', 'text');
        } else if (inputType == 'text') {
            $('.show-pass').removeClass('active');
            passInput.attr('type', 'password');
        }
    }

    function showHideConfPwd() {
        var passInput = $('.show-pass-conf').parent().find('input');
        var inputType = passInput.attr('type');
        if (inputType == 'password') {
            $('.show-pass-conf').toggleClass('active');
            passInput.attr('type', 'text');
        } else if (inputType == 'text') {
            $('.show-pass-conf').removeClass('active');
            passInput.attr('type', 'password');
        }
    }

    const formValidation = () => {
        let passwordError = {};
        let confPasswordError = {};
        let isValid = true;

        if (!password) {
            passwordError.passwordEmpty =
                "Please enter password";
            isValid = false;
        }else if (password.length > 10) {
			passwordError.passwordLength = `Password not greater then ${10} characters`;
			isValid = false;
		}

        if (!confPassword) {
            confPasswordError.confPasswordEmpty =
                "Please enter confirm password";
            isValid = false;
        } else if (password != confPassword) {
            confPasswordError.confPasswordNotMatch =
                "Confirm password does not match";
            isValid = false;
        }

        setPasswordError(passwordError);
        setConfPasswordError(confPasswordError);
        return isValid;
    };

    return (
        <div class="page page-center">
            {validate === true ? <>
                <div class="container container-tight py-4">
                    <div class="card-body text-center py-4 p-sm-5">
                        <img
                            src="https://api.tokenlelo.com/logo/TokenLelo-VLogo-Black.png"
                            height="120"
                            className="mb-n2"
                            alt=""
                        />
                    </div>
                    <form class="card card-md" autocomplete="off" novalidate>
                        <div class="card-body">
                            <h2 class="card-title text-center ">Reset password</h2>
                            <div class="mb-2">
                                <label class="form-label">Password</label>
                                <div class="input-group input-group-flat">
                                    <input type="password" class="form-control" placeholder="Enter Password" autocomplete="off" onChange={(e) => {
                                        setPassword(e.target.value);
                                    }} />
                                    <span class="input-group-text show-pass">
                                        <Link to="" class="link-secondary" id="show-pass" title="Show password" onClick={showHidePwd} data-bs-toggle="tooltip" onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                                        </Link>
                                    </span>
                                </div>
                                {passwordError &&
                                    Object.keys(passwordError).map((key) => {
                                        return (
                                            <div style={{ color: "red" }}>
                                                {passwordError[key]}
                                            </div>
                                        );
                                    })}
                                           </div>
                                           <div class="mb-3">
                                <label class="form-label">Confirm Password</label>
                                <div class="input-group input-group-flat">
                                    <input type="password" class="form-control" placeholder="Enter Confirm Password" autocomplete="off" onChange={(e) => {
                                        setConfPassword(e.target.value);
                                    }} />
                                    <span class="input-group-text show-pass-conf">
                                        <Link to="" class="link-secondary" id="show-pass" title="Show confirm password" onClick={showHideConfPwd} data-bs-toggle="tooltip">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                                        </Link>
                                    </span>
                                </div>
                                {Object.keys(confPasswordError).map((key) => {
                                    return (
                                        <div style={{ color: "red" }}>
                                            {confPasswordError[key]}
                                        </div>
                                    );
                                })}
                            </div>
                            <div class="form-footer mt-0">
                                <Link to="" class="btn btn-primary w-100" onClick={onSubmit}>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg> */}
                                    Submit
                                </Link>
                            </div>
                        </div>
                    </form>
                    <div class="text-center text-muted mt-3">
                        Forget it, <Link to="/sign-in">send me back</Link> to the sign in screen.
                    </div>
                </div>
            </> : <>
                <div class="page-content">
                    <div class="block block-strong">
                        <p>Sorry</p>
                        <p>Something went wrong, please contact Administrator!</p>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default ResetPassword