import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeMenuAction } from "../../actions/index";
import { showLoaderAction } from "../../actions/index";
import SpinerLoader from "../SpinerLoader/spinerLoader";
import { useCatchHandler } from "../../utils/utilities"
const { REACT_APP_MY_ENV } = process.env;

const Categories = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const catchHandle = useCatchHandler();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  let token = localStorage.getItem("adminToken");

  useEffect(() => {
    dispatch(activeMenuAction('category-list'));
    getCategoryList()
  }, []);

  const getCategoryList = async (SearchText) => {
    let data={
      SearchText : SearchText ? SearchText : ""
    }
    dispatch(showLoaderAction(!SearchText));
    const response = await axios.post(REACT_APP_MY_ENV + "/categories-list", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
    if (response.data.status === 200) {
      dispatch(showLoaderAction(false));
      setCategoryList(response.data.data.categoryList)
    } else {
      dispatch(showLoaderAction(false));
      setCategoryList([]);
      // toast.error(response.data.message);
    }}).catch(function (error) {
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
  }

  const getUpdateCategory = async (categories) => {
    let data = {
      categoryId:(categories.categoryId).toString(),
      categoryName: categories.categoryName,
      TopOrder : categories.topOrder,
      IsActive : categories.isActive === 0 ? 1 : 0
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/update-categories", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
    if (response.data.status === 200) {
      dispatch(showLoaderAction(false));
      toast.success(response.data.message);
      getCategoryList()
    } else {
      dispatch(showLoaderAction(false));
      toast.error(response.data.message);
    }}).catch(function (error) {
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
  }

  const onSearchResult = (e) => {
    setSearchText(e.target.value)
  }
  const onSearchHandleClick = (e) => {
    if (searchText) {
      getCategoryList(searchText)
    } else {
      setSearchText("")
      getCategoryList("")
    }
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchHandleClick();
    }
  };
  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div className="page-header d-print-none mt-20">
        <div className="container-xl mt-4">
          <div className="row g-2 align-items-center">
            <div className="col">
              <h2 className="page-title">Categories</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl category-list-mb">
          <div className="row row-deck row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-body border-bottom py-3">
                  <div className="d-flex">
                  <div className="ms-auto text-muted">
                      <div className="ms-auto text-muted">
                        <div className="d-flex">
                          <input type="search" className="form-control d-inline-block w-9 me-2" value={searchText}
                            onKeyDown={handleKeyDown}
                            onChange={onSearchResult} aria-label="Search invoice" placeholder="Search here…" />
                          <button className="btn btn-primary " onClick={onSearchHandleClick}>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap datatable">
                    <thead>
                      <tr>
                        <th className="w-1">
                          Sr. No.
                        </th>
                        <th>Name</th>
                        <th>Top Order</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryList.length > 0 ? categoryList && categoryList.map((category, index) => {
                        return (
                          <tr>
                            <td>
                              <span className="text-muted">{++index}</span></td>
                            <td>{category.categoryName}</td>
                            <td>{category.topOrder}</td>
                            <td>{
                              (<label className="form-check toggle-margin form-check-single form-switch">
                                <input className="form-check-input" type="checkbox"
                                checked={category.isActive === 1}
                                onClick={() => getUpdateCategory(category)} 
                                 />
                              </label>)}</td>
                            <td className="text-end">
                              <span className="dropdown">
                                <div class="btn-group" role="group">
                                  <button id="btnGroupDrop1" type="button" class="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                  </button>
                                  <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1 ">
                                    <li> <Link className="dropdown-item" to={"/update-category/" + category.categoryId} state={{ type: "update" }} >
                                      Edit
                                    </Link></li>
                                  </ul>
                                </div>
                              </span>
                            </td>
                          </tr>
                         );
                        }) :<span className="no-record-message"><p>No record found!</p></span>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Categories;
