
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';

export function useCatchHandler() {
    const navigate = useNavigate();
    const catchHandle = (error) => {
      if (error.response && error.response.status === 401) {
        toast.error('You have been logged out, please re-login again!');
        localStorage.clear();
          navigate('/sign-in');
      } else {
        toast.error(error);
      }
    };
    return catchHandle;
  };