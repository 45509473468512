import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeMenuAction } from "../../actions/index";
import { showLoaderAction } from "../../actions/index";
import toast from 'react-hot-toast';
import SpinerLoader from "../SpinerLoader/spinerLoader";
import { useCatchHandler } from "../../utils/utilities"
import moment from "moment/moment";
const { REACT_APP_MY_ENV } = process.env;

const Plans = () => {
	const dispatch = useDispatch();
	const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
	const showLoader = showLoaderReducer.show;
	const [currentPage, setCurrentPage] = useState(1);
	const [planList, setPlanList] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [searchText, setSearchText] = useState("");
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const pageCount = Math.max(1, Math.ceil(totalCount / itemsPerPage));
	let token = localStorage.getItem("adminToken");
	const catchHandle = useCatchHandler();
	const startIndex = (currentPage - 1) * itemsPerPage;

	useEffect(() => {
		dispatch(activeMenuAction('plan-list'));
		GetAdminPlan("", 1, itemsPerPage);
	}, []);


	const GetAdminPlan = async (Search_Text, pageNumber, itemPerPage) => {
		let data = {
			SearchText: Search_Text ? Search_Text : "",
			pageNumber: !Search_Text || pageNumber ? pageNumber : currentPage,
			pageSize: itemPerPage ? itemPerPage : itemsPerPage,
		}
		dispatch(showLoaderAction(!searchText));
		const response = await axios.post(REACT_APP_MY_ENV + "/admin-plan-list", data,
			{ headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
				if (response.data.status === 200) {
					dispatch(showLoaderAction(false));
					setPlanList(response.data.data.planList)
					setCurrentPage(pageNumber || currentPage)
					setTotalCount(response.data.data.totalPlan)
				} else {
					dispatch(showLoaderAction(false));
					setPlanList([]);
				}
			}).catch(function (error) {
				catchHandle(error)
				dispatch(showLoaderAction(false));
				toast.error("Something went wrong, please contact Administrator!")
			});
		dispatch(showLoaderAction(false));
	}

	const handlePageClick = (selected) => {
		setCurrentPage(selected);
		GetAdminPlan(searchText, selected)
	};

	const handlePageNextClick = () => {
		if (currentPage < pageCount) {
			setCurrentPage((prevPage) => prevPage + 1);
			GetAdminPlan(searchText, currentPage + 1);
		}
	};

	const handlePagePrevClick = () => {
		if (currentPage > 1) {
			setCurrentPage((prevPage) => prevPage - 1);
			GetAdminPlan(searchText, currentPage - 1);
		}
	};

	const handlePageSizeClick = (e) => {
		const value = parseInt(e.target.value);
		setItemsPerPage(value);
		if (e.target.value >= 1) {
			GetAdminPlan("", currentPage, value)
		}
		else {
			setItemsPerPage();
			GetAdminPlan("", 1, value)
		}
	};

	const onSearchResult = (e) => {
		setSearchText(e.target.value)
	}

	const handleSearch = (e) => {
		if (searchText) {
			GetAdminPlan(searchText, 1)

		} else {
			setSearchText("", 1)
			GetAdminPlan("", 1)
		}
	}

	 const getUpdatePlanStatus = async (plan) => {
			let data = {
			  PlanId:plan.planId,
			  planName: plan.name,
			  price : plan.price,
			  monthlyDuration : plan.monthlyDuration,
			  type: plan.type,
			  IsActive : plan.status === 0 ? 1 : 0
			};
			const response = await axios.post(REACT_APP_MY_ENV + "/update-plans", data, {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }}).then(function (response) {
			if (response.data.status === 200) {
			  toast.success(response.data.message);
			  GetAdminPlan("", 1, itemsPerPage)
			} else {
			  toast.error(response.data.message);
			}}).catch(function (error) {
			  catchHandle(error)
			  dispatch(showLoaderAction(false));
			  toast.error("Something went wrong, please contact Administrator!")
			});
		  }		  
	  
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};

	return (
		<>
			{showLoader && (<SpinerLoader />)}
			<div className="page-header d-print-none mt-20">
				<div className="container-xl mt-4">
					<div className="row g-2 align-items-center">
						<div className="col-11">
							<h2 className="page-title">Plans</h2>

						</div>
						<div className="col-1">
							<Link className="btn btn-primary  mb-1" to="/add-plan">
								Add Plan
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="page-body">
				<div className="container-xl client-list-mb">
					<div className="row row-deck row-cards">
						<div className="col-12">
							<div className="card">

								<div className="card-body border-bottom py-3">

									<div className="d-flex">
										<div className="text-muted">
											Show
											<div className="mx-2 d-inline-block">
												<select
													class="form-control"
													onChange={handlePageSizeClick}
													value={itemsPerPage}
													selected={itemsPerPage}
												>
													<option value="10" >10</option>
													<option value="25" >25</option>
													<option value="50" >50</option>
													<option value="100" >100</option>
												</select>
											</div>
											entries
										</div>
										<div className="ms-auto text-muted">
											<div className="d-flex">
												<input type="search" className="form-control d-inline-block w-9 me-2" value={searchText}
													onKeyDown={handleKeyDown}
													onChange={onSearchResult} aria-label="Search invoice" placeholder="Search here…" />
												<button className="btn btn-primary " onClick={handleSearch}>
													Search
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table card-table table-vcenter text-nowrap datatable">
										<thead>
											<tr>
												<th className="w-1">
													Sr. No.
												</th>
												<th>Name</th>
												<th>Type</th>
												<th>Months</th>
												<th>Price</th>
												<th>Modified On</th>
												<th>Status</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{planList.length > 0 ? planList && planList.map((plan, index) => {
												return (
													<tr >
														<td><span className="text-muted">{startIndex + index + 1}</span></td>
														<td>{plan.name ? plan.name : "-"}</td>
														<td>{plan.type ? plan.type : "-"}</td>
														<td>{plan.monthlyDuration ? plan.monthlyDuration : "-"}</td>
														<td>{plan.price ? plan.price : "-"}</td>
														<td>
															{plan.updatedAtUtc ? moment(plan.updatedAtUtc).format("DD-MM-YYYY hh:mm A") : ""}
														</td>
														<td>{
															(<label className="form-check toggle-margin form-check-single form-switch">
																<input className="form-check-input" type="checkbox"
																checked={plan.status === 1}
															    onClick={() => getUpdatePlanStatus(plan)}
																/>
															</label>)}</td>
														<td className="text-end">
															<span className="dropdown">
																<div class="btn-group" role="group">
																	<button id="btnGroupDrop1" type="button" class="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
																		Actions
																	</button>
																	<ul class="dropdown-menu" aria-labelledby="btnGroupDrop1 ">
																		<li> <Link className="dropdown-item"
																			to={"/update-plan/" + plan.planId}
																			state={{ planId: plan.planId, type : "update" }} >
																			Edit
																		</Link></li>
																	</ul>
																</div>
															</span>
														</td>
													</tr>
												);
											}) : <span className="no-record-message"><p>No record found!</p></span>}
										</tbody>
									</table>
								</div>

								<div className="card-footer d-flex align-items-center">
									<p className="m-0 text-muted">
										Showing <span>{currentPage}</span> to <span>{itemsPerPage}</span> of <span>{totalCount}</span> entries
									</p>
									<ul className="pagination m-0 ms-auto">
										<li className="page-item ">
											<Link className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => handlePagePrevClick(currentPage - 1)} tabIndex="-1" aria-disabled="true">
												<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<path d="M15 6l-6 6l6 6" />
												</svg>
												prev
											</Link>
										</li>
										{Array.from({ length: pageCount }, (_, i) => (
											<li key={i} className="page-item">
												<Link
													className={`page-link ${i + 1 === currentPage ? 'active' : ''}`}
													onClick={() => handlePageClick(i + 1)}
												>
													{i + 1}
												</Link>
											</li>
										))}
										<li className="page-item">
											<Link className={`page-link ${currentPage === pageCount ? 'disabled' : ''}`}
												onClick={() => handlePageNextClick(+1)}
											>
												next
												<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<path d="M9 6l6 6l-6 6" />
												</svg>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Plans