import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar'
import Header from './Header';

const SidebarLayout = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <Outlet />
        </>
    )
}
export default SidebarLayout