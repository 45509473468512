import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import toast from "react-hot-toast";
import Select from 'react-select';
import axios from "axios";
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from "react-redux";
import { activeMenuAction } from "../../actions/index";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { useCatchHandler } from "../../utils/utilities";
import { showLoaderAction } from "../../actions/index";
import { Link } from "react-router-dom";
const { REACT_APP_MY_ENV } = process.env;

const Dashboard = () => {
  const currentURL = window.location.href;
  let token = localStorage.getItem("adminToken");
  const catchHandle = useCatchHandler();
  const [client, setClient] = useState([]);
  const currentUrl = new URL(window.location.href);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedClient, setSelectedClient] = useState("");
  const [totalTokens, setTotalTokens] = useState();
  const [inQueueTokens, setInQueueTokens] = useState();
  const [removedTokens, setRemovedTokens] = useState();
  const [completedTokens, setCompletedTokens] = useState();
  const [cancelledTokens, setCancelledTokens] = useState();
  const [favouritesCount, setFavouritesCount] = useState();
  const [reviewCount, setReviewCount] = useState();
  const [userCount, setUserCount] = useState();
  const [filterOptions] = useState(['Today', 'ThisWeek', 'DAY', 'ThisMonth', 'LastMonth', 'ThisYear', 'LastYear', 'AllTime'])
  const [defaultFilter, setDefaultFilter] = useState(null);
  const [dayFilter, setDayFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState(new Date());
  const [clientIdError, setClientIdError] = useState({});
  const [filterError, setFilterError] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(activeMenuAction('dashboard'));
    let isAdminLogin = localStorage.getItem('isAdminLogin')
    if (!isAdminLogin) {
      navigate('/sign-in');
    }
    getClient()
    if (!selectedClient) {
      getUserTokenReports("", undefined, null)
    } else {
      getUserTokenReports("", undefined, selectedClient)
    }
  }, [selectedClient]);

  const getClient = async (pageNumber) => {
    let data = {
      SearchText: "",
      pageNumber: pageNumber ? pageNumber : currentPage,
      pageSize: 10000
    }
    const response = await axios.post(REACT_APP_MY_ENV + "/client-list", data, { headers: { Authorization: `Bearer ${(localStorage.getItem('adminToken'))}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        const userData = response.data.data.searchOutlet;
        let userOptions = [];
        userData.forEach((user) => {
          const userObj = {
            value: user.id,
            label: user.name
          };
          userOptions.push(userObj);
        });
        setClient(userOptions);
      } else {
        setClient([])
      }
    }).catch(function (error) {
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
  }
  const handleClientChange = (selectedOption) => {
    if (selectedOption) {
      const clientId = selectedOption.value.toString();
      setSelectedClient(clientId);
    }
    setDefaultFilter("")
  };

  const getUserTokenReports = async (filter, date, clientId) => {
    try {
      const requestData = {
        encryptedClientId: clientId ? clientId : null,
        filter: filter ? filter : "",
        date: date ? date : new Date()
      };
      dispatch(showLoaderAction(true));
      let response = await axios.post(REACT_APP_MY_ENV + '/get-admin-reports', requestData, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }
      })
      if (response.data.status == 200) {
        dispatch(showLoaderAction(false));
        setTotalTokens(response.data.data.totalTokens);
        setInQueueTokens(response.data.data.inQueueTokens)
        setRemovedTokens(response.data.data.removedTokens)
        setCompletedTokens(response.data.data.completedTokens)
        setCancelledTokens(response.data.data.cancelledTokens)
        setFavouritesCount(response.data.data.favouriteOutletCount)
        setUserCount(response.data.data.userCount)
        setReviewCount(response.data.data.reviewCount)
      }
      else {
        dispatch(showLoaderAction(false));
        // showToast(response.data.message, "error");
      }
    }
    catch (error) {
      catchHandle(error, "false", currentURL)
      dispatch(showLoaderAction(false));
      const data = {
        Subject: "Front end error from get-reports",
        BodyText: error.message
      }

    }
  }

  const validation = () => {
    let clientIdError = {};
    let filterError = {};
    let isValid = true;

    if (!selectedClient) {
      clientIdError.clientIdEmpty =
        "Please select client";
      isValid = false;
    }
    if (!defaultFilter) {
      filterError.filterEmpty =
        "Please select at least one";
      isValid = false;
    }
    setFilterError(filterError);
    setClientIdError(clientIdError);
    return isValid;
  };

  const handleFilter = async (e) => {
    setDefaultFilter(e.target.value);
    if (e.target.value != "DAY") {
      setDayFilter(false)
    }
    else {
      setDayFilter(true)
    }
  }

  const handleFieldChange = async (date, dateSting) => {
    setDateFilter(date)
    getUserTokenReports("DAY", date, selectedClient)
  }

  const handleFilterButton = async (e) => {
    const isValid = validation();
    if (isValid) {
      getUserTokenReports(defaultFilter, undefined, selectedClient)
    }
  }

  const exportToExcel = async () => {
    try {
      const requestData = {
        filter: defaultFilter ? defaultFilter : "", 
        date: dateFilter ? dateFilter : new Date(),
        encryptedClientId: selectedClient ? selectedClient : null 
      };
  
      const response = await axios.post(REACT_APP_MY_ENV + '/get-admin-reports', requestData, {
        headers: { Authorization: `Bearer ${(localStorage.getItem('adminToken'))}`, "Content-Type": "application/json" }
      });
  
      if (response.data.status === 200) {
        const reportData = response.data.data;
  
        if (reportData) {
          const headers = ['Total Tokens', 'In Queue Tokens', 'Removed Tokens', 'Completed Tokens', 'Cancelled Tokens', 'Favourite Outlet Count', 'User Count', 'Review Count'];
          const formattedData = [headers, [
            reportData.totalTokens,
            reportData.inQueueTokens,
            reportData.removedTokens,
            reportData.completedTokens,
            reportData.cancelledTokens,
            reportData.favouriteOutletCount,
            reportData.userCount,
            reportData.reviewCount
          ]];
  
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(formattedData);
          XLSX.utils.book_append_sheet(wb, ws, `${defaultFilter}_Report`);
          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
          const downloadLink = document.createElement('a');
          const url = URL.createObjectURL(dataBlob);
          downloadLink.href = url;
          downloadLink.download = `${defaultFilter}_Report.xlsx`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
        } else {
          console.error('No report data found');
        }
      } else {
        console.error('Error in report response:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  };
  

  const handleClick = () => {
    exportToExcel();
  };

  return (
    <>
      <div className="page-header d-print-none mt-25">
        <div className="page-header d-print-none mt-3">
          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title">
                  Reports
                </h2>
              </div>
              <div className="col-auto ms-auto d-print-none">
                <div className="d-flex">
                  <div>
                    <Select className="selectTheme ClientWith me-1"
                      value={client.find((c) => c.value === selectedClient)}
                      onChange={(selectedOption) => handleClientChange(selectedOption)}
                      options={client}
                    />
                    <p> {clientIdError &&
                      Object.keys(clientIdError).map((key) => {
                        return (
                          <div style={{ color: "red" }}>
                            {clientIdError[key]}
                          </div>
                        );
                      })}</p>
                  </div>
                  <div class="input-icon">
                    <select
                      name="categoryId"
                      class="form-control m-1 mb-0 mt-0 me-1 filter-width"
                      value={defaultFilter}
                      onChange={handleFilter}
                    >
                      <option value="" className="text-secondary">Select</option>
                      {filterOptions.map((filter) => {
                        const modifiedFilter = filter === "ThisWeek" ? "This Week" : filter === "DAY" ? "Day" : filter === "ThisMonth" ? "This Month" : filter === "LastMonth" ? "Last Month" : filter === "ThisYear" ? "This Year" : filter === "LastYear" ? "Last Year" : filter === "AllTime" ? "All Time" : filter;
                        return (
                          <option key={filter} value={filter}>
                            {modifiedFilter}
                          </option>
                        );
                      })}
                    </select>
                    <p className="ms-1">{filterError &&
                      Object.keys(filterError).map((key) => {
                        return (
                          <div style={{ color: "red" }}>
                            {filterError[key]}
                          </div>
                        );
                      })}</p>

                  </div>
                  <div class="input-icon mb-2 me-3">
                    {dayFilter &&
                      <DatePicker
                        className="form-control"
                        format="YYYY-MM-DD"
                        selected={dateFilter}
                        onSelect={(date, dateSting) => handleFieldChange(date, dateSting)}
                        dateFormat="dd/MM/YYYY"
                        maxDate={new Date()}
                        placeholder="Select Preferred Date"
                      />
                    }    </div>
                  <button className="btn btn-primary filter-button-width"
                    onClick={handleFilterButton}
                  >
                    FILTER
                  </button>
                  <div class="input-icon mb-2">
                  <Link className="btn btn-primary ms-1 input-icon" 
                   onClick={handleClick}
                  >
                    Export
                  </Link>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-md-6 col-lg-3 ">
              <div className="card">
                <div className="card-body p-4 text-center d-flex flex-column align-items-center justify-content-center">
                  <h3 className="m-0 mb-1 text-dark"><Link className="remove-underline-on-hover" >Total Token</Link></h3>
                  <div className="text-muted fw-bold text-custom-blue">{totalTokens}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="card">
                <div className="card-body p-4 text-center d-flex flex-column align-items-center justify-content-center">
                  <h3 className="m-0 mb-1 text-dark"><Link className="remove-underline-on-hover" >In-Queue</Link></h3>
                  <div className="text-muted fw-bold text-inQueue">{inQueueTokens}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="card">
                <div className="card-body p-4 text-center d-flex flex-column align-items-center justify-content-center">
                  <h3 className="m-0 mb-1 text-dark"><Link className="remove-underline-on-hover" >Cancelled</Link></h3>
                  <div className="text-muted fw-bold text-cancel">{cancelledTokens}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="card">
                <div className="card-body p-4 text-center d-flex flex-column align-items-center justify-content-center">
                  <h3 className="m-0 mb-1 text-dark"><Link className="remove-underline-on-hover" >Removed</Link></h3>
                  <div className="text-muted fw-bold text-remove">{removedTokens}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="card">
                <div className="card-body p-4 text-center d-flex flex-column align-items-center justify-content-center">
                  <h3 className="m-0 mb-1 text-dark"><Link className="remove-underline-on-hover" >Completed</Link></h3>
                  <div className="text-muted fw-bold text-success">{completedTokens}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="card">
                <div className="card-body p-4 text-center d-flex flex-column align-items-center justify-content-center">
                  <h3 className="m-0 mb-1 text-dark"><Link className="remove-underline-on-hover" >New Customers</Link></h3>
                  <div className="text-muted fw-bold custom-dark-blue">{userCount}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="card">
                <div className="card-body p-4 text-center d-flex flex-column align-items-center justify-content-center">
                  <h3 className="m-0 mb-1 text-dark"><Link className="remove-underline-on-hover" >Favourites</Link></h3>
                  <div className="text-muted fw-bold custom-dark-orange">{favouritesCount}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="card">
                <div className="card-body p-4 text-center d-flex flex-column align-items-center justify-content-center">
                  <h3 className="m-0 mb-1 text-dark"><Link className="remove-underline-on-hover" >Reviews</Link></h3>
                  <div className="text-muted fw-bold custom-dark-green">{reviewCount}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
