export const activeMenuAction = (payload) => {
    return {
      type: 'ACTIVEMENU',
      payload
    };
  };

  export const showLoaderAction = (payload) => {
    return {
      type: 'SHOWLOADER',
      payload
    };
  };