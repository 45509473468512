import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { showLoaderAction } from "../../actions/index";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useCatchHandler } from "../../utils/utilities"
import $ from "jquery";
import Cookies from 'js-cookie';
import secureImage from "./../../assests/img/undraw_secure_login_pdn4.svg"

const { REACT_APP_MY_ENV } = process.env;

const SignIn = () => {
  const catchHandle = useCatchHandler();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState({});
  const [passwordError, setPasswordError] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (localStorage.getItem('clientToken')) {
      isAdminLoggedIn()
      
    }
    const storedEmail = Cookies.get('rememberedEmail');
    const storedPassword = Cookies.get('rememberedPassword');

    if (storedEmail) {
      setEmail(storedEmail);
      setRememberMe(true);
    }

    if (storedPassword) {
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []); 

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const formValidation = () => {
    let clientIdError = {};
    let passwordError = {};
    let isValid = true;
    if (!email) {
      clientIdError.clientIdEmpty =
        "Please enter email address or phone number";
      isValid = false;
    } else if (
      isNaN(email) &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      clientIdError.emailInvalid = "Please enter valid email address";
      isValid = false;
    }
    if (!password) {
      passwordError.passwordEmpty =
        "Please enter password";
      isValid = false;
    }
    setEmailError(clientIdError);
    setPasswordError(passwordError);
    return isValid;
  };

  const isAdminLoggedIn = () => {
    let isAdminLogin = localStorage.getItem('isAdminLogin')
    if (isAdminLogin) {
      navigate('/');
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      const data = {
        Email: email,
        Password: password,
      };
      const res = await axios.post(REACT_APP_MY_ENV + "/super-admin-login", data).then(function (res) {
      if (res.data.status === 200) {
        localStorage.setItem("encryptedAdminId", res.data.data.encryptedId);
        localStorage.setItem("adminToken", res.data.data.token);
        localStorage.setItem("AdminName", res.data.data.name);
        localStorage.setItem("isAdminLogin", true);
        if (rememberMe) {
          Cookies.set('rememberedEmail', email, { expires: 1 }); 
          Cookies.set('rememberedPassword', password, { expires: 1 });
        } else {
          Cookies.remove('rememberedEmail');
          Cookies.remove('rememberedPassword');
        }
        toast.success("login successfully")
        navigate("/");
      } else {
        toast.error(res.data.message);
      }}).catch(function (error) {
        dispatch(showLoaderAction(false));
        toast.error("Something went wrong, please contact Administrator!")
      });
    }
  };

  function showHidePassword() {
    var passInput = $('.input-group-text').parent().find('input');
    var inputType = passInput.attr('type');
    if (inputType == 'password') {
      $('.input-group-text').toggleClass('active');
      passInput.attr('type', 'text');
    } else if (inputType == 'text') {
      $('.input-group-text').removeClass('active');
      passInput.attr('type', 'password');
    }
  }

  return (
    <div class="page page-center">
    <div class="container container-normal py-4">
      <div class="row align-items-center g-4">
        <div class="col-lg">
          <div class="container-tight">
            <div class="text-center mb-4">
              <a href="" class="navbar-brand navbar-brand-autodark">  <img
            src="https://api.tokenlelo.com/logo/Logo-Below-Black-Texts.svg"
            height="120"
            className="mb-n2"
            alt=""
          /> </a>
            </div>
            <div class="card card-md">
              <div class="card-body">
                <h2 class="h2 text-center mb-4">Login to your account</h2>
            <form onSubmit={onSubmit} >
              <div class="mb-3">
                <label class="form-label">Email address</label>
                <input type="email" class="form-control" placeholder="your@email.com" value={ email} autocomplete="off" onChange={(e) => {
                  setEmail(e.target.value);
                }} />
                {emailError &&
                  Object.keys(emailError).map((key) => {
                    return (
                      <div style={{ color: "red" }}>
                        {emailError[key]}
                      </div>
                    );
                  })}
              </div>
              <div class="mb-2">
                <label class="form-label">
                  Password
                  <span class="form-label-description">
                    <Link to="/forget-password">I forgot password</Link>
                  </span>
                </label>
                <div class="input-group input-group-flat">
                  <input type="password" class="form-control" placeholder="Your password" value={password} autocomplete="off" onChange={(e) => {
                    setPassword(e.target.value);
                  }} />
                  <span class="input-group-text">
                    <Link to="" class="link-secondary" id="show-pass" title="Show password" onClick={showHidePassword} data-bs-toggle="tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                    </Link>
                  </span>
                </div>
                {passwordError &&
                    Object.keys(passwordError).map((key) => {
                      return (
                        <div style={{ color: "red" }}>
                          {passwordError[key]}
                        </div>
                      );
                    })}
                      <div class="mb-2 mt-1">
                        <label class="form-check">
                          <input type="checkbox" checked={rememberMe}
                            onChange={handleCheckboxChange} class="form-check-input" />
                          <span class="form-check-label">Remember me on this device</span>
                        </label>
                      </div>
              </div>
              <div class="form-footer">
                <button type="submit" class="btn btn-primary w-100">Sign in</button>
              </div>
            </form>
            </div>
                {/* <div class="hr-text">or</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col"><a href="#" class="btn w-100">
                
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon text-github" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" /></svg>
                        Login with Github
                      </a></div>
                    <div class="col"><a href="#" class="btn w-100">
                  
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon text-twitter" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c0 -.249 1.51 -2.772 1.818 -4.013z" /></svg>
                        Login with Twitter
                      </a></div>
                  </div>
                </div> */}
              </div>
              {/* <div class="text-center text-muted mt-3">
                Don't have account yet? <a href="./sign-up.html" tabindex="-1">Sign up</a>
              </div> */}
            </div>
          </div>
          <div class="col-lg d-none d-lg-block">
            <img src={secureImage} height="300" class="d-block mx-auto" alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn