import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeMenuAction } from "../../actions/index";
import { showLoaderAction } from "../../actions/index";
import Select from "react-select";
import toast from 'react-hot-toast';
import eyeIcon from "../../assests/img/eye-black.svg";
import SpinerLoader from "../SpinerLoader/spinerLoader";
import * as XLSX from 'xlsx';
import { useCatchHandler } from "../../utils/utilities"
const { REACT_APP_MY_ENV } = process.env;

const OutletRequestList = () => {
  const dispatch = useDispatch();
  const showLoaderReducer = useSelector((state) => state.rootReducer.showLoaderReducer)
  const showLoader = showLoaderReducer.show;
  const [outletRequestList, setOutletRequestList] = useState([]);
  
  const statusOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Processed", value: "Processed" },
    { label: "Onboarded", value: "Onboarded" },
    { label: "Dormant", value: "Dormant" },
    { label: "Rejected", value: "Rejected" },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState("");

  const [searchText, setSearchText] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pageCount = Math.max(1, Math.ceil(totalCount / itemsPerPage));
  let token = localStorage.getItem("adminToken");
  const catchHandle = useCatchHandler();
  const startIndex = (currentPage - 1) * itemsPerPage;

  useEffect(() => {
    dispatch(activeMenuAction('user-list'));
    if (outletRequestList.length <= 0)
      getOutletRequestList("", 1, itemsPerPage);
  }, []);

  const getOutletRequestList = async (Search_Text, pageNumber, itemPerPage) => {
    let data = {
      SearchText: Search_Text ? Search_Text : "",
      pageNumber: !Search_Text || pageNumber ? pageNumber : currentPage,
      pageSize: itemPerPage ? itemPerPage : itemsPerPage,
    }
    dispatch(showLoaderAction(!searchText));
    const response = await axios.post(REACT_APP_MY_ENV + "/get-outlet-requests-list", data,
      { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
        if (response.data.status === 200) {
          dispatch(showLoaderAction(false));
          setOutletRequestList(response.data.data.outletRequestList)
          setCurrentPage(pageNumber || currentPage)
          setTotalCount(response.data.data.totalRequestCount)
        } else {
          dispatch(showLoaderAction(false));
          setOutletRequestList([]);
        }
      }).catch(function (error) {
        catchHandle(error)
        dispatch(showLoaderAction(false));
        toast.error("Something went wrong, please contact Administrator!")
      });
    dispatch(showLoaderAction(false));
  }

  const getUpdateRequestStatus = async (request, status) => {
    let data = {
      clientId: (request.id).toString(),
      status: status
    };
    dispatch(showLoaderAction(true));
    const response = await axios.post(REACT_APP_MY_ENV + "/update-request-status", data, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(function (response) {
      if (response.data.status === 200) {
        dispatch(showLoaderAction(false));
        toast.success(response.data.message);
        getOutletRequestList(searchText, currentPage, itemsPerPage);
      } else {
        dispatch(showLoaderAction(false));
        toast.error(response.data.message);
      }
    }).catch(function (error) {
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
      catchHandle(error)
      dispatch(showLoaderAction(false));
      toast.error("Something went wrong, please contact Administrator!")
    });
  }
  const handlePageClick = (selected) => {
    setCurrentPage(selected);
    getOutletRequestList(searchText, selected)
  };

  const handlePageNextClick = () => {

    if (currentPage < pageCount) {
      setCurrentPage((prevPage) => prevPage + 1);
      getOutletRequestList(searchText, currentPage + 1);
    }
  };

  const handlePagePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      getOutletRequestList(searchText, currentPage - 1);
    }
  };

  const handlePageSizeClick = (e) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value);
    if (e.target.value >= 1) {
      getOutletRequestList("", currentPage, value)
    }
    else {
      setItemsPerPage();
      getOutletRequestList("", 1, value)
    }
  };

  const onSearchResult = (e) => {
    setSearchText(e.target.value)
  }

  const handleSearch = (e) => {
    if (searchText) {
      getOutletRequestList(searchText, 1)

    } else {
      setSearchText("")
      getOutletRequestList("", 1)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleStatus = async (selectedOption, request) => {
    setStatus(selectedOption.value);
    getUpdateRequestStatus(request, selectedOption.value)
  };

  const exportToExcel = async () => {
    const requestData = {
      SearchText: searchText,
      pageNumber: 1,
      pageSize: totalCount,
      clientId:""
    };
  
    try {
      const response = await axios.post(REACT_APP_MY_ENV + "/get-outlet-requests-list", requestData, { headers: { Authorization: `Bearer ${(localStorage.getItem('adminToken'))}`, "Content-Type": "application/json" } });
      
      if (response.data.status === 200 ) {
        const outletRequestList = response.data.data.outletRequestList;
        let serialNumber = 1;
        const headers = ['Sr No.', 'Name', 'Phone Number', 'Email','Address','Description', 'RequestFrom', 'Status'];
        const formattedData = [headers, ...outletRequestList.map(data => [
          serialNumber++, 
          data.name,
          data.phoneNumber,
          data.email,
          data.address,
          data.description,
          data.requestFrom,
          data.status
        ])];
        const dateObject = new Date();
        const formattedDateTime = `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(formattedData);
        XLSX.utils.book_append_sheet(wb, ws, `OutletRequest_${formattedDateTime}`);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(dataBlob);
        downloadLink.href = url;
        downloadLink.download =  `OutletRequest_${formattedDateTime}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
      } else {
        console.error('No data found or error in response');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleClick = () => {
    exportToExcel();
  };

  return (
    <>
      {showLoader && (<SpinerLoader />)}
      <div className="page-header d-print-none mt-20">
        <div className="container-xl mt-4">
          <div className="row g-2 align-items-center">
            <div className="col">
              <h2 className="page-title">Outlet Requests</h2>
            </div>
            <div class="col-auto ms-auto d-print-none">
              <div class="d-flex">
                <div class="page-content overflow-hidden pt-70">
                  <div class="container show-button">
                    <Link className="btn btn-primary ms-1 input-icon"
                     onClick={handleClick}
                     >
                    Export
                  </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl client-list-mb">
          <div className="row row-deck row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-body border-bottom py-3">
                  <div className="d-flex">
                    <div className="text-muted">
                      Show
                      <div className="mx-2 d-inline-block">
                        <select
                          class="form-control"
                          onChange={handlePageSizeClick}
                          value={itemsPerPage}
                          selected={itemsPerPage}
                        >
                          <option value="10" >10</option>
                          <option value="25" >25</option>
                          <option value="50" >50</option>
                          <option value="100" >100</option>
                        </select>
                      </div>
                      entries
                    </div>
                    <div className="ms-auto text-muted">
                      <div className="d-flex">
                        <input type="search" className="form-control d-inline-block w-9 me-2" value={searchText}
                          onKeyDown={handleKeyDown}
                          onChange={onSearchResult} aria-label="Search invoice" placeholder="Search here…" />
                        <button className="btn btn-primary " onClick={handleSearch}>
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap datatable">
                    <thead>
                      <tr>
                        <th className="w-1">
                          Sr. No.
                        </th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Description</th>
                        <th>RequestFrom</th>
                        <th>Attachment</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {outletRequestList.length > 0 ? outletRequestList && outletRequestList.map((outletRequest, index) => {
                        return (
                          <tr>
                            <td>
                              <span className="text-muted">{startIndex + index + 1}</span></td>
                            <td>{outletRequest.name}</td>
                            <td>{outletRequest.phoneNumber}</td>
                            <td>{outletRequest.email ? outletRequest.email : "-"}</td>
                            <td> <span title={outletRequest.address ? outletRequest.address : ""} data-bs-toggle="tooltip"
                              data-bs-placement="bottom" class="text-message" >{outletRequest.address ? outletRequest.address : "-"}</span></td>
                            <td> <span title={outletRequest.description ? outletRequest.description : ""} data-bs-toggle="tooltip"
                              data-bs-placement="bottom" class="text-message" >{outletRequest.description ? outletRequest.description : "-"}</span></td>
                            <td><span class="badge bg-cyan badge-pill">{outletRequest.requestFrom ? outletRequest.requestFrom : "-"}</span></td>
                            <td className="image-size-width">{<a href={outletRequest.attachmentPath ? outletRequest.attachmentPath : "javascript:void(0);"} target="_blank" className="link">
                              <img src={eyeIcon} alt="icon" />
                            </a>}</td>
                            <td>
                              <Select
                                value={{ label: outletRequest.status, value: outletRequest.status }}
                                className="selectTheme"
                                name="selectStatus"
                                placeholder="Select"
                                options={statusOptions}
                                onChange={(selectedOption) => handleStatus(selectedOption, outletRequest)}
                              />
                            </td>
                          </tr>
                        );
                      }) : <span className="no-record-message"><p>No record found!</p></span>}
                    </tbody>
                  </table>
                </div>
                <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">
                    Showing <span>{currentPage}</span> to <span>{itemsPerPage}</span> of <span>{totalCount}</span> entries
                  </p>
                  <ul className="pagination m-0 ms-auto">
                    <li className="page-item ">
                      <Link className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => handlePagePrevClick(currentPage - 1)} tabIndex="-1" aria-disabled="true">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M15 6l-6 6l6 6" />
                        </svg>
                        prev
                      </Link>
                    </li>
                    {Array.from({ length: pageCount }, (_, i) => (
                      <li key={i} className="page-item">
                        <Link
                          className={`page-link ${i + 1 === currentPage ? 'active' : ''}`}
                          onClick={() => handlePageClick(i + 1)}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    ))}
                    <li className="page-item">
                      <Link className={`page-link ${currentPage === pageCount ? 'disabled' : ''}`}
                        onClick={() => handlePageNextClick(+1)}
                      >
                        next
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 6l6 6l-6 6" />
                        </svg>
                      </Link>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OutletRequestList